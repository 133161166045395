import React, { useState, ReactNode } from 'react';
import { AppointmentModel } from '../../../api/model/appointment';
import { MilestoneModel } from '../../../api/model/milestone';
import { ConstructionModel } from '../../../api/model/construction';
import { HouseholdModel } from 'api/model/household';
import { datetimeStringJp } from '../../../libs/date';
import ClientHomeCard from '../../molecules/ClientHomeCard';
import HomeSlide from '../../organisms/HomeSlide';
import { SystemMessageModel } from '../../../api/model/systemMessage';
import { SystemMessage } from '../../atoms/SystemMessage';
import HomeFlowModal from '../../organisms/HomeFlowModal';
import ReviewModal from 'components/organisms/ReviewModal';
import { UserModel } from 'api/model/user';
import Welcome from 'components/molecules/Welcome';

const defaultProps = {
  appointments: [],
  systemMessages: [],
  milestone: {},
  construction: {},
};

type Props = {
  appointments: AppointmentModel[];
  construction: ConstructionModel;
  user: UserModel;
  milestone: MilestoneModel;
  systemMessages: SystemMessageModel[];
  children?: ReactNode;
  households: HouseholdModel[] | null;
  hasUsingHouseholds: boolean;
  onSubmitReview: (
    rating: number,
    comment: string | null,
    userId: number
  ) => void;
} & typeof defaultProps;

export default function Home(props: Props) {
  const {
    appointments,
    milestone,
    user,
    construction,
    systemMessages,
    households,
    hasUsingHouseholds,
    onSubmitReview,
  } = props;

  const constructionData = {
    house_name: construction?.house_name,
    nextMileStone: construction?.next_milestone?.label,
    nextMileStoneShort: construction?.next_milestone?.label_short,
    nextMileStoneDate: construction?.next_milestone?.date_short,
    nextMileStoneLeftDays: construction?.next_milestone?.left_days_short,
    checkItemProgress: construction?.progress,
    koujiProgress: construction?.kouji_progress,
    koujiProcess: construction?.kouji_process,
    contracted: construction.contracted,
    handedOver4monthsAgo: construction.handed_over_4months_ago,
    ignoreVersionMaster: construction.ignore_version_master,
  };

  const [openModal, setOpenModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(user.should_review);

  // 契約後と直近の打ち合わせのみ表示
  const cardCata = appointments.map((appointment) => {
    return {
      appointmentType: appointment.appointment_type,
      appointmentDay: appointment.date,
      appointmentTitle: appointment.title,
      appointmentPlace: appointment.place,
      appointmentTime: `${datetimeStringJp(
        appointment.begin_at
      )}〜${datetimeStringJp(appointment.end_at)}`,
      appointmentNote: appointment.note,
      households: appointment.households,
      conferenceUrl: appointment.display_conference
        ? appointment.conference_url
        : '',
      agendaList: appointment.agendas?.map((agenda) => {
        const ignordCount = '0 /' + agenda.total_articles_count;
        const completedCount =
          agenda.completed_articles_count + '/' + agenda.total_articles_count;
        return {
          id: agenda.id,
          image: agenda.image,
          name: agenda.name,
          number: `${agenda.ignored ? ignordCount : completedCount}`,
          flag: agenda.completed_articles_count === agenda.total_articles_count,
          inConstructionProcess: agenda.in_construction_process,
          householdFlags: agenda.household_flags,
          ignored: agenda.ignored,
        };
      }),
      recordFiles: appointment.record_files?.map((recordFile) => {
        return {
          name: `${recordFile.name}`,
          url: `${recordFile.url}`,
          thumb_url: `${recordFile.thumb_url}`,
        };
      }),
      relatedFiles: appointment.related_files?.map((relatedFile) => {
        return {
          name: relatedFile.name,
          url: relatedFile.url,
          thumb_url: `${relatedFile.thumb_url}`,
        };
      }),
      hasUsingHouseholds: hasUsingHouseholds,
    };
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <div id="pageTop" className="container">
      <ClientHomeCard
        construction={constructionData}
        milestones={milestone}
        openModal={handleOpenModal}
      />
      {systemMessages.length !== 0 && (
        <SystemMessage systemMessages={systemMessages} />
      )}
      {cardCata && cardCata.length > 0 && constructionData && (
        <HomeSlide
          data={cardCata}
          construction={constructionData}
          milestone={milestone}
          households={households}
          hasUsingHouseholds={hasUsingHouseholds}
        />
      )}
      {/* 契約後は打ち合わせが必ず存在する。未契約ユーザーがアクセスした直後では打ち合わせは存在しない */}
      {cardCata.length === 0 && constructionData && <Welcome />}
      <HomeFlowModal
        milestones={construction.milestones}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      />
      <ReviewModal
        open={openReviewModal}
        userId={user.id}
        onClose={() => {
          setOpenReviewModal(false);
        }}
        onSubmitReview={(
          rating: number,
          comment: string | null,
          userId: number
        ) => onSubmitReview(rating, comment, userId)}
      />
    </div>
  );
}

Home.defaultProps = defaultProps;
