import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import {
  fetchDataFromSalesforce,
  fetchProjectContractStatus,
  loginFromMitsuiHomeclub,
} from '../../api/user';
import { UserModel } from '../../api/model/user';
import { serializeError } from 'serialize-error';
import { getGitUser } from 'api/github';

type GitUserLoaded = {
  name: string;
};

type LoginLoaded = {
  user: UserModel;
};

type ProjectContractStatus = {
  // status: 'success' | 'error';
  contract_status: 0 | 1; // 0: 未契約, 1: 契約済み
};

type SsoFailure = {
  message: string;
};

type UserState = {
  name: string;
  user: UserModel;
  deletedUser: boolean;
  loggedIn: boolean;
  loading: boolean;
  error: any;
  ssoErrorMessage: string;
  slideOpen: boolean;
  should_review: boolean;
};

const initialState: UserState = {
  name: '', // README(git)用に設置
  user: {} as UserModel,
  deletedUser: false,
  loggedIn: false,
  loading: false,
  error: null,
  ssoErrorMessage: '',
  slideOpen: false,
  should_review: false,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleSlideNav(state) {
      if (state.slideOpen === false) {
        state.slideOpen = true;
      } else {
        state.slideOpen = false;
      }
    },
    // === README用 ===
    getGitUserStart(state) {
      state.loading = true;
    },
    getGitUserSuccess(state, action: PayloadAction<GitUserLoaded>) {
      const { name } = action.payload;
      state.name = name;
      state.loading = false;
      state.error = null;
    },
    getGitUserFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    // =================
    loginUserStart(state) {
      state.loading = true;
    },
    loginUserSuccess(state, action: PayloadAction<LoginLoaded>) {
      const { user } = action.payload;
      state.user = user;
      state.loggedIn = true;
      state.loading = false;
      state.error = null;
    },
    loginUserFailure(state, action: PayloadAction<Error | string>) {
      state.error = serializeError(action.payload);
      if (action.payload === '削除済みユーザー') {
        state.deletedUser = true;
      }
      state.loggedIn = false;
      state.loading = false;
    },
    ssoFailure(state, action: PayloadAction<SsoFailure>) {
      const { message } = action.payload;
      state.ssoErrorMessage = message;
    },
    getProjectContractStatusStart(state) {
      state.loading = true;
    },
    getProjectContractStatusSuccess(
      state,
      action: PayloadAction<ProjectContractStatus>
    ) {
      const { contract_status } = action.payload;
      const isContracted = contract_status === 1;
      state.user.login_construction.available_order_selfstyling = isContracted;
      state.loading = false;
    },
    getProjectContractStatusFailure(state, action: PayloadAction<Error>) {
      // 例）error_massege : "統合IDとプロジェクトIDが紐づいていません",
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetMessageCount(state) {
      if (state.user) {
        state.user.unread_message_count = 0;
      }
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  toggleSlideNav,
  getGitUserSuccess,
  getGitUserStart,
  getGitUserFailure,
  loginUserSuccess,
  loginUserStart,
  loginUserFailure,
  resetMessageCount,
  resetError,
  getProjectContractStatusStart,
  getProjectContractStatusSuccess,
  getProjectContractStatusFailure,
} = user.actions;
export default user.reducer;

export const fetchGitUser =
  (name: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGitUserStart());
      const res = await getGitUser({ name });
      dispatch(getGitUserSuccess({ name: res.data.login }));
    } catch (err: any) {
      dispatch(getGitUserFailure(err));
      throw err;
    }
  };

export const resetNotificationCount = (): AppThunk => async (dispatch) => {
  try {
    dispatch(resetMessageCount());
  } catch (err: any) {
    throw err;
  }
};

export const loginUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loginUserStart());
    const resFromMitsuiHomeClub = await loginFromMitsuiHomeclub();
    if (resFromMitsuiHomeClub.data.data.user) {
      dispatch(
        loginUserSuccess({
          user: resFromMitsuiHomeClub.data.data.user,
        })
      );
      if (process.env.REACT_APP_ORDER_SELFSTYLING_RELEASE === 'true') {
        const sfProjectId =
          resFromMitsuiHomeClub.data.data.user.login_construction.sf_project_id;
        if (sfProjectId === null || sfProjectId === '') return;
        try {
          // REACT_APP_ENV_NAMEがdevelopmentの場合はAPIを叩かない。契約済みとする
          if (process.env.REACT_APP_ENV_NAME === 'development') {
            const contractStatus = 1;
            dispatch(
              getProjectContractStatusSuccess({
                contract_status: contractStatus,
              })
            );
          } else {
            const resFromOrderSelfstyling = await fetchProjectContractStatus({
              federation_id: 'web-tool-test', // 現状はなくてもいい
              project_id: sfProjectId,
            });
            dispatch(
              getProjectContractStatusSuccess({
                contract_status: resFromOrderSelfstyling.data.contract_status,
              })
            );
          }
        } catch (err: any) {
          if (err.code === 'ERR_BAD_REQUEST') {
            // セルフスタイリング側で統合IDとプロジェクトIDが紐づいていない場合（あるいはデータが存在しない場合）は未契約とする
            const contractStatus = 0;
            dispatch(
              getProjectContractStatusSuccess({
                contract_status: contractStatus,
              })
            );
          } else {
            dispatch(getProjectContractStatusFailure(err));
            throw err;
          }
        }
      }
    } else if (resFromMitsuiHomeClub.data.data.deleted_user) {
      throw '削除済みユーザー';
    }
  } catch (err: any) {
    dispatch(loginUserFailure(err));
    throw err;
  }
};

export const checkLoggedIn = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loginUserStart());
    const resFromSalesforce = await fetchDataFromSalesforce();
    if (resFromSalesforce.data.data.user) {
      dispatch(
        loginUserSuccess({
          user: resFromSalesforce.data.data.user,
        })
      );
      if (process.env.REACT_APP_ORDER_SELFSTYLING_RELEASE === 'true') {
        // ここでセルフスタイリングフォーオーダーAPIを叩く（リロード時のリクエスト）
        // 同じ処理をsrc/features/Callback/CallBack/index.tsxでも実行している
        try {
          // REACT_APP_ENV_NAMEがdevelopmentの場合はAPIを叩かない。契約済みとする
          if (process.env.REACT_APP_ENV_NAME === 'development') {
            const contractStatus = 1;
            dispatch(
              getProjectContractStatusSuccess({
                contract_status: contractStatus,
              })
            );
          } else {
            const resFromOrderSelfstyling = await fetchProjectContractStatus({
              federation_id: resFromSalesforce.data.data.user.sf_id,
              project_id:
                resFromSalesforce.data.data.user.login_construction
                  .sf_project_id,
            });
            dispatch(
              getProjectContractStatusSuccess({
                contract_status: resFromOrderSelfstyling.data.contract_status,
              })
            );
          }
        } catch (err: any) {
          if (err.code === 'ERR_BAD_REQUEST') {
            // セルフスタイリング側で統合IDとプロジェクトIDが紐づいていない場合（あるいはデータが存在しない場合）は未契約とする
            const contractStatus = 0;
            dispatch(
              getProjectContractStatusSuccess({
                contract_status: contractStatus,
              })
            );
          } else {
            dispatch(getProjectContractStatusFailure(err));
            throw err;
          }
        }
      }
    } else if (resFromSalesforce.data.data.deleted_user) {
      throw '削除済みユーザー';
    }
  } catch (err: any) {
    dispatch(loginUserFailure(err));
    throw err;
  }
};
