import { ReactNode } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export type Props = {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

const useStyles = makeStyles<Theme, { disabled: boolean }>(() =>
  createStyles({
    root: {
      border: '1.5px solid #d6d6d6',
      borderRadius: 9,
      filter: 'none',
      cursor: (props) => (props.disabled ? 'default' : 'pointer'),
    },
    content: {
      padding: '16px',
      '&.MuiCardContent-root:last-child': {
        paddingBottom: '16px',
      },
    },
  })
);

export default function QuoteCard({
  children,
  onClick,
  disabled = false,
}: Props) {
  const classes = useStyles({ disabled: disabled });

  return (
    <Card
      className={`${classes.root}`}
      variant="outlined"
      onClick={disabled ? undefined : onClick}
    >
      <CardContent className={`${classes.content}`}>{children}</CardContent>
    </Card>
  );
}
