import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'App/rootReducer';
import { toggleMailFlag } from '../notificationSettingSlice';
import * as Sentry from '@sentry/browser';
import NotificationSetting from 'components/pages/NotificationSetting';
import { fetchNotificationSettings } from '../notificationSettingSlice';

type Props = {
  children?: ReactNode;
};

export default function NotificationSettingWithRedux() {
  const dispatch = useDispatch<any>();
  const notificationSettings = useSelector(
    (state: RootState) => state.notificationSetting.notificationSettings
  ) as any;

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchNotificationSettings());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  const toggleMailFlagSetting = (userEmailId: number, mailType: string) => {
    async function f() {
      try {
        await dispatch(toggleMailFlag(userEmailId, mailType));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  return (
    <>
      <NotificationSetting
        notificationSettings={notificationSettings}
        handleToggleMailFlag={(userEmailId: number, mailType: string) =>
          toggleMailFlagSetting(userEmailId, mailType)
        }
      />
    </>
  );
}
