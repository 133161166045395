import React, { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router';
import * as Sentry from '@sentry/browser';
import AuthWithSalesforce from 'components/atoms/AuthWithSalesforce';
import { useDispatch, useSelector } from 'react-redux';
import { checkLoggedIn } from 'features/user/userSlice';
import { RootState } from 'App/rootReducer';

type Props = {
  children?: ReactNode;
  token?: string;
  from?: any;
};

export default function ConnectedSalesforce(props: Props) {
  const location = useLocation();
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const setLoggedIn = sessionStorage.getItem('setLoggedIn');
  const user = useSelector((state: RootState) => state.user.user);
  const setRedirected = sessionStorage.getItem('setRedirected');
  const dispatch = useDispatch<any>();
  const authParams = new URLSearchParams();
  const { children } = props;
  authParams.append('response_type', `${'code'}`);
  authParams.append(
    'client_id',
    `${process.env.REACT_APP_CIDENTITY_CLIENT_ID}`
  );
  authParams.append(
    'redirect_uri',
    `${process.env.REACT_APP_REDIRECT_SALESFORCE}`
  );

  const getErrorMessage = () => {
    return 'アクセスに失敗しました。再度操作を行なってください。';
  };

  useEffect(() => {
    async function func() {
      try {
        if (user && setLoggedIn) {
          await dispatch(checkLoggedIn());
        }
      } catch (error) {
        const message = getErrorMessage();
        dispatch({
          type: 'user/ssoFailure',
          payload: { message },
        });
        sessionStorage.clear();
        localStorage.removeItem('whichAuth');
        // Sentry.captureException(error);}
      }
    }
    func();
  }, []);

  const checkedToken = () => {
    if (!loggedIn && !setRedirected) {
      return (
        <AuthWithSalesforce
          prevUrl={location.pathname}
          redirectUrl={`${process.env.REACT_APP_ROOT_SALESFORCE}?${authParams.toString()}`}
        >
          {children}
        </AuthWithSalesforce>
      );
    }
  };

  return <>{loggedIn ? children : checkedToken()}</>;
}
