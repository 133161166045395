import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  // Checkbox,
  // CheckboxProps,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  // withStyles
} from '@material-ui/core';
// import { green } from "@material-ui/core/colors";
import DateFnsUtils from '@date-io/date-fns';
import { FormData } from '../../../api/model/task';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import ja from 'date-fns/locale/ja';
import { format } from 'date-fns';
import TaskHistoryModal from '../TaskHistoryModal';
import PostMessageModal from '../PostMessageModal';
import { createMessage } from 'features/message/messageSlice';
import { TaskModel } from 'api/model/task';
import { QuotableType } from 'api/message';

const defaultProps = {
  loading: false,
};

type Props = {
  handleTaskUpdate: (params: FormData) => void;
  handleTaskDelete: (taskId: number) => void;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFetchTaskHistory: (taskId: number) => void;
  selectedTask?: TaskModel;
  clearSelectedTask: () => void;
} & typeof defaultProps;

// 担当者を実行者に設定する機能追加時に使用**********************************
// const GreenCheckbox = withStyles({
//   root: {
//     color: green[400],
//     "&$checked": {
//       color: green[600]
//     }
//   },
//   checked: {}
// })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
//********************************************************************

// カレンダーヘッダー部分を日本語化
class JaLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date) {
    return format(date, 'M月d日(E)');
  }
}

const theme = createTheme({
  typography: {
    body1: {
      fontSize: '1.5rem',
    },
    body2: {
      fontSize: '1.5rem',
    },
    caption: {
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#ff0000',
    },
  },
});

export default function TaskDrawer(props: Props) {
  const {
    setDrawerOpen,
    handleTaskUpdate,
    handleTaskDelete,
    onFetchTaskHistory,
    selectedTask,
    clearSelectedTask,
  } = props;

  const dispatch = useDispatch<any>();
  const [error, setError] = useState(false);
  const [messages, setMessages] = useState(['']);
  const [openTaskHistoryModal, setOpenTaskHistoryModal] = useState(false);
  const [openPostMessageModal, setOpenPostMessageModal] = useState(false);

  // Snackbarを閉じる関数
  const handleClose = () => {
    setMessages(['']);
    setError(false);
  };

  const [title, setTitle] = useState(selectedTask?.title);
  const onChangeTitle = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => setTitle(e.target.value as string);

  const [sharedStatus, setSharedStatus] = useState(selectedTask?.shared_status);
  const onChangeSharedStatus = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => setSharedStatus(e.target.value as string);

  const [priority, setPriority] = useState(selectedTask?.priority);
  const onChangePriority = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => setPriority(e.target.value as string);

  const [progressStatus, setProgressStatus] = useState(
    selectedTask?.progress_status
  );
  const onChangeProgressStatus = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => setProgressStatus(e.target.value as string);

  const [dueDate, setDueDate] = useState(selectedTask?.due_date || null);
  const handleDateChange = (date: any) => {
    setDueDate(date);
  };

  const [canEdit, setCanEdit] = useState(selectedTask?.can_edit);
  const onChangeCanEdit = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setCanEdit(e.target.value === 1);
  };

  const [detail, setDetail] = useState(selectedTask?.detail || '');
  const onChangeDetail = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => setDetail(e.target.value as string);

  //実行者が担当者の機能追加時に使用 **********************************
  // const [isNotificationEmail, setIsNotificationEmail] = useState(true);
  // const handleNotificationEmailChange = (
  //   event: ChangeEvent<HTMLInputElement>
  // ) => {
  //   setIsNotificationEmail(event.target.checked);
  // };
  //  ************************************************************

  // 値が編集されたか検知
  const hasChanges = () => {
    return (
      selectedTask?.title !== title ||
      selectedTask?.shared_status !== sharedStatus ||
      selectedTask?.priority !== priority ||
      selectedTask?.progress_status !== progressStatus ||
      selectedTask?.due_date !== dueDate ||
      selectedTask?.can_edit !== canEdit ||
      (selectedTask?.detail ?? '') !== detail
    );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params: FormData = {
      taskId: selectedTask?.id ? selectedTask.id : 0,
      title: title ? title : '',
      detail: detail ? detail : '',
      dueDate: dueDate ? dueDate : null,
      priority: priority ? priority : '',
      progressStatus: progressStatus ? progressStatus : '',
      sharedStatus: sharedStatus ? sharedStatus : '',
      // colorId: colorId, // 追加予定の値
      trashed: selectedTask?.trashed ? selectedTask.trashed : false,
    };
    const errorMessages = validateFormData(params);
    if (errorMessages.length > 0) {
      setMessages(errorMessages);
      setError(true);
      return;
    }
    handleTaskUpdate(params);
  };

  const onClickTrashOrRestore = (
    event: React.MouseEvent<HTMLImageElement>,
    toTrash: boolean
  ) => {
    event?.preventDefault();
    handleTrashStatus(toTrash);
  };

  // trashedのステータスのみ変更する
  const handleTrashStatus = (toTrash: boolean) => {
    const params: FormData = {
      taskId: selectedTask?.id ? selectedTask.id : 0,
      title: selectedTask?.title ? selectedTask.title : '',
      detail: selectedTask?.detail ? selectedTask.detail : '',
      dueDate: selectedTask?.due_date ? selectedTask.due_date : null,
      priority: selectedTask?.priority ? selectedTask.priority : '',
      progressStatus: selectedTask?.progress_status
        ? selectedTask.progress_status
        : '',
      sharedStatus: selectedTask?.shared_status
        ? selectedTask.shared_status
        : '',
      // colorId: colorId, // 追加予定の値
      trashed: toTrash, // このステータスのみ変更
    };
    const errorMessages = validateTrashStatus();
    if (errorMessages.length > 0) {
      setMessages(errorMessages);
      setError(true);
      return;
    }
    handleTaskUpdate(params);
  };

  // タスクがゴミ箱 or 編集権限「なし」の場合、disable(true)を返す
  const setFormDisabled = () => {
    return selectedTask?.trashed || !selectedTask?.can_edit;
  };

  const validateFormData = (params: FormData): string[] => {
    const errors: string[] = [];

    // 公開したタスクは非公開にもどすことができない
    if (
      selectedTask?.shared_status === 'public_use' &&
      params.sharedStatus !== 'public_use'
    ) {
      errors.push('・一度公開したタスクを非公開にもどすことができません');
    }

    return errors;
  };

  const validateTrashStatus = (): string[] => {
    const errors: string[] = [];
    if (!selectedTask?.can_edit) {
      errors.push('・編集権限がないため、タスクの移動ができません');
    }
    return errors;
  };

  const onClickDateReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setDueDate(null);
  };

  const handleTaskDeleteCompletely = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (!selectedTask?.can_edit) {
      setMessages(['・編集権限がないため、タスクの削除ができません']);
      setError(true);
      return;
    }
    handleTaskDelete(selectedTask?.id);
  };

  const handleActivityLogClick = async () => {
    await onFetchTaskHistory(Number(selectedTask?.id));
    setOpenTaskHistoryModal(true);
  };

  const handlePostMessageIconClick = () => {
    if (selectedTask?.shared_status !== 'public_use') {
      setMessages([
        '・タスクの共有状態が「公開」の場合のみ、掲示板で投稿が可能です',
      ]);
      setError(true);
      return;
    }
    setOpenPostMessageModal(true);
  };

  const handlePostMessage = async (
    body: string,
    quotableId: number,
    quotableType: QuotableType
  ) => {
    try {
      await dispatch(
        createMessage(body, [], null, undefined, quotableId, quotableType)
      );
      setOpenPostMessageModal(false);
    } catch (error) {
      throw error;
    }
  };

  const handleCloseIconClick = () => {
    clearSelectedTask();
    setDrawerOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="Task__drawer-form">
        <div className="Task__drawer-container">
          <div className="Task__drawer-top">
            <img
              className="task-post-message-icon"
              src="/assets/img/tasks/icon/post_message.svg"
              alt="掲示板で投稿"
              onClick={() => handlePostMessageIconClick()}
            />
            <img
              className="task-close-icon"
              src="/assets/img/common/icon/close.svg"
              alt=""
              onClick={() => handleCloseIconClick()}
            />
          </div>
          {/* 実行者を表示する要望があったらアンコメントする */}
          {/* <div className="Task__drawer-executor">
            <img
              className="icon"
              src="/assets/img/tasks/icon/executor.svg"
              alt="実行者"
            />
            <p className="executor">{selectedTask?.executable_name}様</p>
          </div> */}
          <div>
            <TextField
              id="standard-multiline-static"
              multiline
              variant="standard"
              defaultValue={title}
              onChange={onChangeTitle}
              disabled={setFormDisabled()}
              className="Task__drawer-title"
              helperText={!title && '入力が必要です'}
              error={!title}
              InputProps={{
                style: {
                  fontSize: '2.0rem',
                },
              }}
            />
          </div>
          <div className="Task__drawer-content">
            <div className="Task__drawer-content-contributor">
              <p className="contributor">
                作成者： {selectedTask?.creatable_name}
              </p>
              <p className="contributor">
                最終編集者： {selectedTask?.editable_name}
              </p>
            </div>
          </div>
          <div className="Task__drawer-content">
            <div className="Task__drawer-content-selector">
              <p>共有状態</p>
              <FormControl>
                <Select
                  labelId="shared-status"
                  id="shared-status"
                  value={sharedStatus}
                  onChange={onChangeSharedStatus}
                  className="select"
                  disabled={setFormDisabled()}
                >
                  <MenuItem value="public_use">公開</MenuItem>
                  <MenuItem value="private_use">非公開</MenuItem>
                  {/* 担当者を実行者に設定する機能追加時に使用 */}
                  {/* <MenuItem value="draft">下書き</MenuItem> */}
                </Select>
                {sharedStatus === 'public_use' &&
                  selectedTask?.shared_status === 'private_use' && (
                    <FormHelperText className="helper-text">
                      「公開」に変更後、戻すことはできません
                    </FormHelperText>
                  )}
              </FormControl>
            </div>

            <div className="Task__drawer-content-selector">
              <p>優先度</p>
              <FormControl>
                <Select
                  labelId="priority"
                  id="priority"
                  value={priority}
                  onChange={onChangePriority}
                  className="select"
                  disabled={setFormDisabled()}
                >
                  <MenuItem value="unset">なし</MenuItem>
                  <MenuItem value="low">低</MenuItem>
                  <MenuItem value="middle">中</MenuItem>
                  <MenuItem value="high">高</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="Task__drawer-content-selector">
              <p>進捗状況</p>
              <FormControl>
                <Select
                  labelId="progress-status"
                  id="progress-status"
                  value={progressStatus}
                  onChange={onChangeProgressStatus}
                  className="select"
                  disabled={
                    selectedTask?.trashed || selectedTask?.is_material_task
                  }
                >
                  <MenuItem value="incompleted">未完了</MenuItem>
                  <MenuItem value="completed">完了</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="Task__drawer-content-selector">
              <p>締切</p>
              <div className="Task__drawer-content-date">
                <ThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={ja}>
                    <DatePicker
                      disableToolbar
                      value={dueDate}
                      onChange={handleDateChange}
                      variant="inline"
                      format="yyyy/MM/dd (E)"
                      className="datePicker"
                      disabled={setFormDisabled()}
                      InputProps={{
                        style: {
                          fontSize: '1.2rem',
                          display: 'flex',
                          justifyContent: 'center',
                          paddingLeft: '3rem',
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
                <button
                  className="reset-button"
                  onClick={(event) => onClickDateReset(event)}
                  disabled={setFormDisabled()}
                >
                  ✕
                </button>
              </div>
            </div>

            <div className="Task__drawer-content-selector">
              <p>編集権限</p>
              <FormControl>
                <Select
                  labelId="can-edit"
                  id="can-edit"
                  value={canEdit ? 1 : 0}
                  onChange={onChangeCanEdit}
                  className="select"
                  disabled
                >
                  <MenuItem value={0}>なし</MenuItem>
                  <MenuItem value={1}>あり</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="Task__drawer-content">
            <textarea
              placeholder="詳細を追加"
              className="Task__drawer-content-memo"
              onChange={onChangeDetail}
              value={detail}
              disabled={setFormDisabled()}
            />
          </div>

          <div className="Task__drawer-content">
            <div className="Task__drawer-content-look-activity-log">
              <img
                className="icon"
                src="/assets/img/tasks/icon/look_activity_log.svg"
                alt="アクティビティログを見る"
                onClick={() => handleActivityLogClick()}
              />
            </div>
          </div>
        </div>
        <div className="Task__drawer-bottom">
          {/* 担当者を実行者にする機能を追加する際にコメントを外す **************/}
          {/* <div className="Task__drawer-bottom-notification-email">
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={isNotificationEmail}
                onChange={handleNotificationEmailChange}
                name="checkedG"
              />
            }
            label="担当者にメールでお知らせする"
          />
        </div> */}
          {/* ******************************************************** */}

          {selectedTask?.trashed ? (
            <div className="Task__drawer-bottom-deepest-part">
              <img
                className="trash-box-icon"
                src="/assets/img/tasks/icon/restore_from_trash.svg"
                alt="もとに戻す"
                onClick={(event) => onClickTrashOrRestore(event, false)}
              />
              <button
                type="submit"
                className="delete-button"
                onClick={handleTaskDeleteCompletely}
              >
                完全に削除
              </button>
            </div>
          ) : (
            <div className="Task__drawer-bottom-deepest-part">
              <img
                className="trash-box-icon"
                src="/assets/img/tasks/icon/to_trash.svg"
                alt="ゴミ箱へ移動"
                onClick={(event) => onClickTrashOrRestore(event, true)}
              />
              <button
                type="submit"
                className={`save-button ${
                  hasChanges()
                    ? 'save-button-has-change'
                    : 'save-button-no-change'
                }`}
                disabled={!hasChanges()}
              >
                変更を保存
              </button>
            </div>
          )}
        </div>
      </form>
      <Snackbar
        autoHideDuration={3000}
        color="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(error)}
        onClose={handleClose}
        message={
          <div>
            {messages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </div>
        }
      />
      <TaskHistoryModal
        open={openTaskHistoryModal}
        onClose={() => {
          setOpenTaskHistoryModal(false);
        }}
      />
      <PostMessageModal
        open={openPostMessageModal}
        onClose={() => {
          setOpenPostMessageModal(false);
        }}
        modalTitle="掲示板で連絡"
        id={selectedTask?.id}
        handlePostMessage={handlePostMessage}
        inputHint="この「やること」についての質問内容を入力してください"
      />
    </>
  );
}

TaskDrawer.defaultProps = defaultProps;
