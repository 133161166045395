import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { fetchDesignSteps } from '../designStepSlice';
import { fetchHouseholds } from '../../household/householdSlice';
import * as Sentry from '@sentry/browser';
import Articles from '../../../components/pages/Articles';

type Props = {
  children?: ReactNode;
};

export default function ArticleWithRedux(props: Props) {
  const dispatch = useDispatch<any>();

  // TODO: as any良くない
  const designSteps = useSelector(
    (state: RootState) => state.designStep.designSteps
  ) as any;

  const households = useSelector(
    (state: RootState) => state.household.households
  ) as any;

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchDesignSteps());
        await dispatch(fetchHouseholds());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  return (
    <>
      <Articles designSteps={designSteps} households={households} />
    </>
  );
}
