import { apiClient } from './client';
import { DesignStepModel } from './model/designStep';

export type FetchRes = {
  data: {
    design_steps: DesignStepModel[];
  };
};

export async function fetch() {
  return apiClient.get<FetchRes>(`/users/design_steps`);
}

export type UploadPdfRes = {
  data: {
    pdf_url: string;
  };
};

export async function uploadPdf() {
  return apiClient.post<UploadPdfRes>(`/users/design_steps/uploads`);
}
