// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import { useNavigate, useLocation } from 'react-router-dom';
import NavBase, { Item } from '../../atoms/NavBase';
import { format } from 'date-fns';
import { logoutFromSalesforce } from 'api/user';

const defaultProps = {
  open: false,
  onChange: () => {},
  availableOrderSelfstyling: false,
};

export type Props = {
  children?: ReactNode;
} & typeof defaultProps;

export const navlist = [
  {
    id: 'home',
    name: 'ホーム',
    path: '/',
    class: '-home',
  },
  {
    id: 'messages',
    name: '掲示板',
    path: '/messages',
    class: '-messages',
  },
  {
    id: 'schedule',
    name: 'スケジュール',
    path: '/schedules',
    class: '-schedule',
  },
  {
    id: 'tasks',
    name: 'やること',
    path: '/tasks',
    class: '-tasks',
  },
  {
    id: 'furniture',
    name: '家具家電',
    path: '/furniture',
    class: '-furniture',
  },
  {
    id: 'files',
    name: 'ファイル',
    path: '/files',
    class: '-files',
  },
];

export const guideList = [
  // ↓セルフスタイリングAPIのレスポンスデータが「契約済み」だった場合に表示
  {
    id: 'MyPage',
    name: 'マイページ',
    path: '/my_page',
    class: '-myPage',
  },
  {
    id: 'OrderSelfstyling',
    name: 'Order Selfstyling',
    path: '/selfstyling',
    class: '-orderSelfstyling',
  },
  {
    id: 'tutorial',
    name: '使い方',
    path: '/tutorials',
    class: '-tutorial',
  },
  {
    id: 'checkItem',
    name: '確認事項',
    path: '/idsheet',
    class: '-checkItem',
  },
  {
    id: 'column',
    name: 'コラム一覧',
    path: '/articles',
    class: '-column',
  },
  {
    id: 'notificationSetting',
    name: '通知設定',
    path: '/notification_settings',
    class: '-notificationSetting',
  },
  {
    id: 'keep',
    name: 'データ保存方法',
    path: '/keeps',
    class: '-keep',
  },
  {
    id: 'mitsuihomeclub',
    name: '三井ホーム倶楽部',
    path: '/mitsuihomeclub',
    class: '-mitsuihomeclub',
  },
  {
    id: 'logout',
    name: 'ログアウト',
    path: '/logout',
    class: '-logout',
  },
];

export const SideMenu = (props: Props) => {
  const { open, onChange, availableOrderSelfstyling } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const construction = useSelector(
    (state: RootState) => state.user.user.login_construction
  );
  const user = useSelector((state: RootState) => state.user.user);
  const keyCsrf = 'navi_token';
  const keySsoRedirect = 'is_sso_redirect';
  const authenticated = localStorage.getItem('whichAuth');

  const navProps = {
    ulClass: 'Nav__list-pc -menu',
    textClass: 'Nav__list-item',
    items: navlist,
    current: location.pathname,
    ignoreVersionMaster: construction.ignore_version_master,
    unreadMessageCount: user.unread_message_count,
    onClick(item: Item) {
      navigate(item.path);
    },
  };

  const handledGuideList = () => {
    // セルフスタイリングの表示 / 非表示切り替え制御
    const excludeGuideList =
      process.env.REACT_APP_ORDER_SELFSTYLING_RELEASE === 'true' &&
      availableOrderSelfstyling
        ? guideList
        : guideList.filter((item) => item.id !== 'OrderSelfstyling');

    // Salesforceのマイページ表示 / 非表示切り替え制御
    if (authenticated === 'Salesforce') {
      return excludeGuideList;
    } else {
      return excludeGuideList.filter((item) => item.id !== 'MyPage');
    }
  };

  const guideProps = {
    ulClass: 'Nav__list-pc -guide',
    liClass: 'Nav__list-item',
    items: handledGuideList(),
    current: location.pathname,
    ignoreVersionMaster: construction.ignore_version_master,
    async onClick(item: Item) {
      if (item.id === 'mitsuihomeclub') {
        window.open(process.env.REACT_APP_CLUB_PAGE, '_blank', 'noreferrer');
        onChange();
      } else if (item.id === 'MyPage') {
        window.open(process.env.REACT_APP_MYPAGE_PAGE, '_blank', 'noreferrer');
        onChange();
      } else if (item.id === 'OrderSelfstyling') {
        const url = new URL(process.env.REACT_APP_ORDER_SELFSTYLING_PAGE);
        url.searchParams.append('project_id', construction.sf_project_id);
        window.open(url.toString(), '_blank', 'noreferrer');
        onChange();
      } else if (item.id === 'logout') {
        if (authenticated === 'MitsuiHomeClub') {
          localStorage.setItem(keyCsrf, '');
          localStorage.setItem(keySsoRedirect, 'false');
          sessionStorage.clear();
          window.location.replace(process.env.REACT_APP_LOGOUT_PAGE);
          onChange();
        } else {
          await logoutFromSalesforce();
          sessionStorage.clear();
          window.location.replace(process.env.REACT_APP_LOGOUT_SALESFORCE);
        }
        localStorage.removeItem('whichAuth');
      } else {
        navigate(item.path);
        onChange();
      }
    },
  };

  const guideSpProps = { ...guideProps, ...{ ulClass: 'Nav__list-sp -guide' } };
  const thisYear = format(new Date(), 'yyyy');

  return (
    <>
      <aside className="Nav__root-pc">
        {/* // TODO: molecules/Tab のようなコンポーネントに置き換える */}
        {/* PCのときのみ表示 */}
        <div className="Nav__inner-pc">
          <div className="Nav__head-pc">
            <h1>
              <img
                className="Nav__logo-pc"
                src="/assets/img/common/logo_nav.svg"
                alt="住まいづくりナビ"
              />
            </h1>
          </div>
          <NavBase {...navProps} />
          <NavBase {...guideProps} />
          <div className="mh-copyright">
            <div className="mh-copyright__privacy">
              <a
                className="mh-copyright__privacy-row"
                href="https://www.mitsuihome.co.jp/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                個人情報保護方針
              </a>
              <a
                className="mh-copyright__privacy-row"
                href="https://www.mitsuihome.co.jp/privacy/#h_toriatukai"
                target="_blank"
                rel="noreferrer"
              >
                個人情報の取扱いについて
              </a>
              <a
                className="mh-copyright__privacy-row"
                href="https://www.mitsuihome.co.jp/privacy/#h_tokuteikojin"
                target="_blank"
                rel="noreferrer"
              >
                特定個人情報基本方針
              </a>
              <a className="mh-copyright__privacy-row" href="/attention">
                サイト利用上の注意
              </a>
              <a
                className="mh-copyright__privacy-row"
                href="https://www.mitsuihome.co.jp/privacy/cookie_accesslog.html"
                target="_blank"
                rel="noreferrer"
              >
                Cookieおよびアクセスログについて
              </a>
            </div>
            <div className="mh-copyright__logo">
              <a
                className="mh-copyright__logo-corporate"
                href="https://www.mitsuihome.co.jp/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/img/common/logo_180.svg" alt="三井ホーム" />
              </a>
              <a
                className="mh-copyright__logo-group"
                href="https://www.mitsuifudosan.co.jp/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/img/common/mitsuifudosan_logo.svg"
                  alt="三井不動産グループ"
                />
              </a>
            </div>
            <p>©1997-{thisYear} MITSUIHOME CO.,LTD.</p>
          </div>
        </div>
      </aside>
      <aside
        className="Nav__root-sp -slide"
        style={{ marginLeft: open ? '0' : '-33.0rem' }}
      >
        {/* SPのときのみ表示 */}
        <nav>
          <NavBase {...guideSpProps} />
        </nav>
        <div className="mh-copyright">
          <div className="mh-copyright__privacy">
            <a
              className="mh-copyright__privacy-row"
              href="https://www.mitsuihome.co.jp/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              個人情報保護方針
            </a>
            <p>|</p>
            <a
              className="mh-copyright__privacy-row"
              href="https://www.mitsuihome.co.jp/privacy/#h_toriatukai"
              target="_blank"
              rel="noreferrer"
            >
              個人情報の取扱いについて
            </a>
            <a
              className="mh-copyright__privacy-row"
              href="https://www.mitsuihome.co.jp/privacy/#h_tokuteikojin"
              target="_blank"
              rel="noreferrer"
            >
              特定個人情報基本方針
            </a>
            <p>|</p>
            <a className="mh-copyright__privacy-row" href="/attention">
              サイト利用上の注意
            </a>
            <a
              className="mh-copyright__privacy-row"
              href="https://www.mitsuihome.co.jp/privacy/cookie_accesslog.html"
              target="_blank"
              rel="noreferrer"
            >
              Cookieおよびアクセスログについて
            </a>
          </div>
          <div className="mh-copyright__logo">
            <a
              className="mh-copyright__logo-corporate"
              href="https://www.mitsuihome.co.jp/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/img/common/logo_180.svg" alt="三井ホーム" />
            </a>
            <a
              className="mh-copyright__logo-group"
              href="https://www.mitsuifudosan.co.jp/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/img/common/mitsuifudosan_logo.svg"
                alt="三井不動産グループ"
              />
            </a>
          </div>
          <p>©1997-{thisYear} MITSUIHOME CO.,LTD.</p>
        </div>
      </aside>
    </>
  );
};

SideMenu.defaultProps = defaultProps;
export default SideMenu;
