import DOMPurify from 'dompurify';

export const linkifyAndSanitizeHTML = (htmlString: string | null) => {
  if (!htmlString) {
    return '';
  }
  // NOTE: dangerouslySetInnerHTMLでtarget属性が削られるため、hrefがマッチしたらtargetを追加する
  const replacedhtml = htmlString.replace(
    /<a\s+(?:[^>]*?\s+)?href="(.*?)"(?:[^>]*?)>/g,
    '<a target="_blank" rel="noreferrer noopener" href="$1">'
  );
  // pタグ、spanタグ内のhttpsをリンク化
  const linkedHtml = replacedhtml.replace(
    /(<(?:p|span).*?>)?([^<]*?)(https?:\/\/[\w.~:/?#[\]@!$&'()*+,;=-]+)([^<]*?)(<\/(?:p|span)>)?/g,
    (match, p1, p2, p3, p4, p5) => {
      if (p3.match(/^(https?:\/\/[\w.~:/?#[\]@!$&'()*+,;=-]+)$/)) {
        return `${
          p1 || ''
        }${p2}<a target="_blank" rel="noreferrer noopener" href="${p3}">${p3}</a>${
          p4 || ''
        }${p5 || ''}`;
      } else {
        return match;
      }
    }
  );
  // NOTE: sanitizeでtarget属性は許可されていないため許可属性として追加する
  return DOMPurify.sanitize(linkedHtml, {
    ADD_ATTR: ['target'],
  });
};
