import { Box } from '@material-ui/core';
import { ArticleModel } from 'api/model/article';
import { QuoteMessageModel } from 'api/model/message';
import { TaskModel } from 'api/model/task';
import MessageQuoteCard from 'components/molecules/MessageQuoteCard';
import NormalQuoteCard from 'components/molecules/NormalQuoteCard';
import styled from 'styled-components';

type Props = {
  task: TaskModel | null;
  article: ArticleModel | null;
  message: QuoteMessageModel | null;
  onClickMessageQuote: (serialNumber: number) => void | null;
};

const RootBox = styled(Box)(() => ({
  marginTop: 20,
  marginBottom: 10,
  width: '80%',
}));

export const QuoteBox = ({
  task,
  article,
  message,
  onClickMessageQuote,
}: Props) => {
  return (
    <RootBox>
      {task && (
        <NormalQuoteCard id={task.id} title={task.title} contents={'task'} />
      )}
      {article && (
        <NormalQuoteCard
          id={article.id}
          title={article.name}
          contents={'material'}
        />
      )}
      {message && (
        <MessageQuoteCard
          message={message}
          onClick={() => {
            onClickMessageQuote(message.message.serial_number);
          }}
        />
      )}
    </RootBox>
  );
};
