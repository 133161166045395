import { apiClient } from './client';
import { ReactionModel } from './model/reaction';

export type CreateParams = {
  messageId: number;
  code: string;
};

export type DestroyParams = {
  messageId: number;
  reactionId: number;
};

export type ReactionRes = {
  data: {
    reactions: ReactionModel[];
  };
};

export async function create(params: CreateParams) {
  const body = {
    code: params.code,
  };
  return apiClient.post<ReactionRes>(
    `/users/messages/${params.messageId}/reactions`,
    body
  );
}

export async function destroy(params: DestroyParams) {
  return apiClient.delete<ReactionRes>(
    `/users/messages/${params.messageId}/reactions/${params.reactionId}`
  );
}
