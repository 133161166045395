import { Typography } from '@material-ui/core';
import { MessageModel, QuoteMessageModel } from 'api/model/message';
import QuoteCard from 'components/atoms/QuoteCard';
import { linkifyAndSanitizeHTML } from 'libs/html';
import { styled } from 'styled-components';

type Props = {
  message: QuoteMessageModel | MessageModel;
  onClick?: () => void;
  disabled?: boolean;
};

const TitleTypography = styled(Typography)(() => ({
  fontSize: '1.4rem',
  fontWeight: 600,
}));

const BodyTypography = styled(Typography)(() => ({
  marginTop: '1rem',
  fontSize: '1.3rem',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  lineHeight: 1.6,
  maxHeight: '3.2em',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
}));

export const MessageQuoteCard = ({
  message,
  onClick,
  disabled = false,
}: Props) => {
  return (
    <QuoteCard onClick={onClick} disabled={disabled}>
      {message.message.deleted ? (
        <TitleTypography color="textSecondary">
          引用された投稿が削除されました
        </TitleTypography>
      ) : (
        <>
          <TitleTypography color="textSecondary">
            {message.message.message_type === 'normal'
              ? message.sender.name
              : message.message.title}
          </TitleTypography>
          <BodyTypography
            color="textSecondary"
            dangerouslySetInnerHTML={{
              __html: linkifyAndSanitizeHTML(message.message.body),
            }}
          />
        </>
      )}
    </QuoteCard>
  );
};

export default MessageQuoteCard;
