import React, { ReactNode } from 'react';
import A from '../../atoms/A';

const defaultProps = {
  message: '',
};

type Props = {
  children?: ReactNode;
  message: string;
} & typeof defaultProps;

export default function SsoError(props: Props) {
  const { message } = props;
  return (
    <div className="Error">
      <h1 className="Error__title -icon-caution">エラーが発生しました</h1>
      <img
        className="Error__image"
        src="/assets/img/common/icon/error_icon.svg"
        alt=""
      />
      <p className="Error__text">{message}</p>
      {process.env.REACT_APP_SALESFORCE_RELEASE === 'true' ? (
        <A
          href={'/login_top'}
          className="Error__btn"
          onClick={() => {
            sessionStorage.clear();
            localStorage.removeItem('whichAuth');
          }}
        >
          ログイン選択画面に戻る
        </A>
      ) : (
        <A href={'/'} className="Error__btn">
          ホーム画面に戻る
        </A>
      )}
    </div>
  );
}

SsoError.defaultProps = defaultProps;
