import React, { createRef, useEffect } from 'react';

const defaultProps = {
  redirectUrl: '',
  prevUrl: '/',
} as Partial<Props>;

type Props = {
  redirectUrl: string;
  prevUrl: string;
};

// SalesForceログイン画面へPOSTでリダイレクトさせる
const SsoFromSalesForce = (props: Props) => {
  const { redirectUrl } = props;
  const ref = createRef<HTMLFormElement>();
  useEffect(() => {
    sessionStorage.setItem('setRedirected', 'true');
    if (ref.current) {
      ref.current.submit();
    }
  }, []);

  return (
    <>
      <form action={redirectUrl} method="POST" ref={ref} />
    </>
  );
};

SsoFromSalesForce.defaultProps = defaultProps;

export default SsoFromSalesForce;
