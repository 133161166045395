import React, { createRef, useEffect } from 'react';

const defaultProps = {
  redirectUrl: '',
  prevUrl: '/',
  csrfToken: '',
  redirect: false,
} as Partial<Props>;

type Props = {
  redirectUrl: string;
  prevUrl: string;
  csrfToken: string;
  loggedIn: boolean;
  redirect: boolean;
};

// 三井ホームクラブへPOSTでリダイレクトさせる
const Sso = (props: Props) => {
  const { redirectUrl, prevUrl, csrfToken, redirect } = props;
  const ref = createRef<HTMLFormElement>();

  useEffect(() => {
    sessionStorage.setItem('setRedirected', 'true');
    if (redirect) {
      if (ref.current) {
        ref.current.submit();
      }
    }
  }, [redirect]);

  return (
    <>
      <form action={redirectUrl} method="POST" ref={ref}>
        <input type="hidden" name="prev_url" value={prevUrl} />
        <input type="hidden" name="csrf_token" value={csrfToken} />
      </form>
    </>
  );
};

Sso.defaultProps = defaultProps;

export default Sso;
