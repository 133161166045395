import { fetchProjectContractStatus, loginFromSalesforce } from 'api/user';
import {
  getProjectContractStatusFailure,
  getProjectContractStatusSuccess,
  loginUserSuccess,
} from 'features/user/userSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

export const CallbackSalesforce = () => {
  const navigate = useNavigate();
  const code = new URLSearchParams(location.search).get('code');
  const dispatch = useDispatch<any>();

  useEffect(() => {
    async function f() {
      try {
        const resFromSalesforce = await loginFromSalesforce({
          code: code,
        } as any);
        await dispatch(
          loginUserSuccess({
            user: resFromSalesforce.data.data.user,
          })
        );
        if (process.env.REACT_APP_ORDER_SELFSTYLING_RELEASE === 'true') {
          // ここでセルフスタイリングフォーオーダーAPIを叩く（login_topでsalesforceを選択したときのリクエスト）
          // 同じ処理をsrc/features/user/userSlice.tsでも実行している
          try {
            // REACT_APP_ENV_NAMEがdevelopmentの場合はAPIを叩かない。契約済みとする
            if (process.env.REACT_APP_ENV_NAME === 'development') {
              const contractStatus = 1;
              dispatch(
                getProjectContractStatusSuccess({
                  contract_status: contractStatus,
                })
              );
            } else {
              const resFromOrderSelfstyling = await fetchProjectContractStatus({
                federation_id: resFromSalesforce.data.data.user.sf_id,
                project_id:
                  resFromSalesforce.data.data.user.login_construction
                    .sf_project_id,
              });
              dispatch(
                getProjectContractStatusSuccess({
                  contract_status: resFromOrderSelfstyling.data.contract_status,
                })
              );
            }
          } catch (err: any) {
            if (err.code === 'ERR_BAD_REQUEST') {
              // セルフスタイリング側で統合IDとプロジェクトIDが紐づいていない場合（あるいはデータが存在しない場合）は未契約とする
              const contractStatus = 0;
              dispatch(
                getProjectContractStatusSuccess({
                  contract_status: contractStatus,
                })
              );
            } else {
              dispatch(getProjectContractStatusFailure(err));
              throw err;
            }
          }
        }
      } catch (error) {
        sessionStorage.clear();
        localStorage.removeItem('whichAuth');
        navigate('/login_top');
        // Sentry.captureException(error);
      }
    }
    f();
  }, []);
  return (
    <>
      <Navigate to={'/'} />
    </>
  );
};
