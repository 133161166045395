import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment, IconButton } from '@material-ui/core';
import { CloseRounded, Event } from '@material-ui/icons';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AlertDialog from 'components/atoms/AlertDialog';
import jaLocale from 'date-fns/locale/ja';
import { DayNumber, getNextHour } from 'libs/date';
import { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';

type Props = {
  value: Date | null;
  onChange: (value: Date | null) => void;
};

const mediaAllSp = 'all and (max-width: 768px)';

const Picker = styled(DateTimePicker)`
  background-color: #ffffff;
  padding-right: 1rem;
  max-width: 20rem;
  margin-left: 1rem;

  input {
    display: block;
    width: 10rem;
    height: 3.2rem;
    border: none;
    border-radius: 0.4rem;
    background-color: #ffffff;
    color: #595757;
    font-size: 1.2rem;
    cursor: default;

    @media ${mediaAllSp} {
      height: 2.2rem;
    }
  }
`;

export const DateHourInput = ({ value, onChange }: Props) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickClearButton: MouseEventHandler = (event) => {
    event?.stopPropagation();
    onChange(null);
  };

  const shouldDisableDate = (day: MaterialUiPickersDate): boolean => {
    // 水曜日は選択できないようにする
    return day?.getDay() === DayNumber.WEDNESDAY;
  };

  const handleOnChange = (date: MaterialUiPickersDate | null) => {
    if (!date) {
      setError(false);
      setHelperText('');
      onChange(date);
      return;
    }

    if (date <= new Date()) {
      setError(true);
      setHelperText('過去の日付・時間は選択できません');
      return;
    }

    const hours = date.getHours();
    if (hours < 8 || hours > 18) {
      setError(true);
      setHelperText('選択可能な時間は8時から19時の間です');
      return;
    }
    setError(false);
    setHelperText('');
    onChange(date);
  };

  const handleAlertClose = () => {
    setError(false);
    setHelperText('');
  };

  const handleClickCalenderButton = () => {
    setOpen(true);
  };

  const handleClosePicker = () => {
    setOpen(false);
  };

  return (
    <>
      <AlertDialog
        open={error}
        onClose={handleAlertClose}
        onAgree={() => {}}
        title="送信予定日時を設定できません。"
        description={helperText}
        singleButton
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
        <Picker
          open={open}
          value={value}
          onChange={handleOnChange}
          onClose={handleClosePicker}
          variant="dialog"
          format="yyyy/MM/dd HH:00"
          margin="normal"
          initialFocusedDate={getNextHour(new Date())}
          minutesStep={60} // 00分しか選択できないようにする
          disablePast
          shouldDisableDate={shouldDisableDate}
          InputProps={{
            disableUnderline: true,
            placeholder: '送信予約日時',
            startAdornment: (
              <InputAdornment position="end">
                <IconButton type="button" onClick={handleClickCalenderButton}>
                  <Event />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: value && (
              <InputAdornment position="end">
                <IconButton type="button" onClick={handleClickClearButton}>
                  <CloseRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
          views={['date', 'hours']}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
