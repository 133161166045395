import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import QuoteCard from '../../atoms/QuoteCard';
import { styled } from 'styled-components';

export type Props = {
  id: number;
  title: string;
  contents: 'task' | 'material';
};

const TitleTypography = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 'bold',
}));

export default function NormalQuoteCard(props: Props) {
  const navigate = useNavigate();
  const { id, title, contents } = props;

  const onClickCard = () => {
    if (contents === 'task') {
      navigate(`/tasks#task_id-${id}`);
    } else {
      navigate(`/articles/${id}#message`);
    }
  };

  return (
    <QuoteCard onClick={onClickCard}>
      <TitleTypography color="textSecondary">
        {contents === 'task'
          ? `やること：「 ${title} 」 について`
          : `部資材仕様特性：「 ${title} 」 についての質問`}
      </TitleTypography>
    </QuoteCard>
  );
}
