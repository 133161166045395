import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/rootReducer';
import {
  fetchTasks,
  createTask,
  updateTask,
  deleteTask,
  sortTask,
  fetchTaskHistory,
  fetchTaskDetail,
} from '../taskSlice';
import { FormData as AddFormData } from 'components/molecules/forms/AddTaskForm';
import { FormData } from '../../../api/model/task';
import Tasks from '../../../components/pages/Tasks';
import { TaskModel } from 'api/model/task';
import {
  createLockedRecord,
  deleteLockedRecord,
} from 'features/lockedRecord/lockedRecordSlice';
import { LockedRecordModel } from 'api/model/lockedRecord';

type Props = {
  children?: ReactNode;
};

export default function TaskWithRedux(props: Props) {
  const dispatch = useDispatch<any>();
  const construction = useSelector(
    (state: RootState) => state.user.user.login_construction
  );
  const tasks = useSelector(
    (state: RootState) => state.task.tasks
  ) as TaskModel[];
  const lockedRecord = useSelector(
    (state: RootState) => state.lockedRecord.lockedRecord
  ) as LockedRecordModel;

  useEffect(() => {
    async function f() {
      try {
        if (construction) {
          // TODO: ↓はexecutableが増える場合(実行者=担当者)はFormからparamsを送るように変更
          await dispatch(fetchTasks(true));
        }
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [construction]);

  const onSubmitTask = async (params: AddFormData) => {
    try {
      const title = params.title;
      await dispatch(createTask(title));
    } catch (error) {
      // Sentry.captureException(error);
      throw error;
    }
  };

  const onUpdateTask = async (params: FormData) => {
    try {
      const title = params.title;
      const detail = params.detail;
      const dueDate = params.dueDate;
      const priority = params.priority;
      const progressStatus = params.progressStatus;
      const sharedStatus = params.sharedStatus;
      // const colorId = params.colorId; 今後追加予定
      const trashed = params.trashed;
      await dispatch(
        updateTask(
          params.taskId!,
          title,
          detail,
          dueDate,
          priority,
          progressStatus,
          sharedStatus,
          // colorId 今後追加予定
          trashed
        )
      );
    } catch (error) {
      // Sentry.captureException(error);
      throw error;
    }
  };

  const onSortTask = async (taskId: number, moveTo: number) => {
    if (construction) {
      try {
        await dispatch(sortTask(taskId, moveTo));
        // state.loadingが更新されると並び替え順が一瞬もとに戻ってしまうためloadingしない
        await dispatch(fetchTasks(false));
      } catch (error) {
        // Sentry.captureException(error);
        throw error;
      }
    }
  };

  const onDeleteTask = (taskId: number) => {
    async function f() {
      if (construction) {
        try {
          await dispatch(deleteTask(taskId));
          await dispatch(fetchTasks(true));
        } catch (error) {
          // Sentry.captureException(error);
          throw error;
        }
      }
    }
    f();
  };

  const onFetchTaskHistory = async (taskId: number) => {
    try {
      await dispatch(fetchTaskHistory(taskId));
    } catch (error) {
      // Sentry.captureException(error);
      throw error;
    }
  };

  const onFetchTaskDetail = async (taskId: number) => {
    try {
      await dispatch(fetchTaskDetail(taskId));
    } catch (error) {
      // Sentry.captureException(error);
      throw error;
    }
  };

  const handleRequestEdit = async (
    lockableType: string,
    lockableId: number
  ) => {
    try {
      await dispatch(createLockedRecord(lockableType, lockableId));
    } catch (error) {
      throw error;
    }
  };

  const handleUnlock = async (lockedRecordId: number) => {
    try {
      await dispatch(deleteLockedRecord(lockedRecordId));
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Tasks
        tasks={tasks}
        onSubmitTask={onSubmitTask}
        construction={construction}
        onUpdateTask={onUpdateTask}
        onSortTask={onSortTask}
        onDeleteTask={onDeleteTask}
        onFetchTaskHistory={onFetchTaskHistory}
        onFetchTaskDetail={onFetchTaskDetail}
        handleRequestEdit={handleRequestEdit}
        handleUnlock={handleUnlock}
        lockedRecord={lockedRecord}
      />
    </>
  );
}
