import React, { useState } from 'react';
import { ConstructionCardModel } from '../../../api/model/userCard';
import { MilestoneModel } from 'api/model/milestone';
import { yeardateStringJp } from '../../../libs/date';
import _ from 'lodash';
import Circle from '../../atoms/Circle';
import FlipCard from '../../atoms/effects/Flip/';

export interface IClientHomeCardProps {}

const defaultProps = {
  construction: {},
  milestones: {},
};

export type Props = {
  construction: ConstructionCardModel;
  milestones?: MilestoneModel;
  openModal: () => void;
} & Omit<typeof defaultProps, 'construction'>;

const milestonesIndex = [
  { label: '契約', index: 0 },
  { label: '設計完了承諾', index: 1 },
  { label: '工事図面の最終確認', index: 2 },
  { label: '着工前金額の確認', index: 3 },
  { label: '地縄確認', index: 4 },
  { label: '上棟検査', index: 5 },
  { label: 'お客様最終確認', index: 6 },
  { label: 'お引渡し', index: 7 },
];

// NOTE: APIのレスポンスと紐づいているので、変更時注意
const nextMilestoneArray = [
  { label: '設計完了承諾 予定', index: 1 },
  { label: '実施設計完了承認 予定', index: 2 },
  { label: '請負金額等確認書 予定', index: 3 },
  { label: '地縄 予定', index: 4 },
  { label: '上棟検査 予定', index: 5 },
  { label: 'お客様確認 予定', index: 6 },
  { label: '引き渡し 予定', index: 7 },
  { label: '次の予定はありません', index: 8 },
];

const compareProgress = (
  mileStoneIndex: number,
  nextMileStoneLabel: string
) => {
  const nextMileStoneIndex = nextMilestoneArray.find(
    (milestone) => milestone.label === nextMileStoneLabel
  );

  return nextMileStoneIndex && nextMileStoneIndex.index > mileStoneIndex;
};

// NOTE: APIのレスポンスと紐づいているので、変更時注意
const convertMileStoneLabel = (nextMileStoneLabel: string) => {
  switch (nextMileStoneLabel) {
    case '申請製造承認 予定':
      return '設計完了承諾 予定';

    case '実施設計完了承認 予定':
      return '工事図面の最終確認 予定';

    case '請負金額等確認書 予定':
      return '着工前金額の確認 予定';

    case '地縄 予定':
      return '地縄確認 予定';

    case 'お客様確認 予定':
      return 'お客様最終確認 予定';

    case '引き渡し 予定':
      return 'お引渡し 予定';

    default:
      return nextMileStoneLabel;
  }
};

export default function ClientHomeCard(props: Props) {
  const { construction, milestones, openModal } = props;
  const [flip, setFlip] = useState(false);

  const milestoneProgress = {
    contract_date: milestones?.contract_date,
    manufacturing_approval_plan_date:
      milestones?.manufacturing_approval_plan_date,
    design_approval_plan_date: milestones?.design_approval_plan_date,
    ukekaku_plan_date: milestones?.ukekaku_plan_date,
    jinawa_plan_date: milestones?.jinawa_plan_date,
    joutou_plan_date: milestones?.joutou_plan_date,
    sesyu_kakunin_plan_date: milestones?.sesyu_kakunin_plan_date,
    handover_plan_date: milestones?.handover_plan_date,
  };

  let value = 0;

  _.each(milestonesIndex, (date, key) => {
    if (!construction?.contracted) return;
    if (
      construction &&
      _.size(milestoneProgress) > 0 &&
      compareProgress(date.index, construction.nextMileStone)
    )
      if (date.index <= _.size(milestonesIndex) / 2) {
        // 矢印の位置調整のため分岐 （+2する）
        value = value + Math.floor(100 / _.size(milestonesIndex) + 2);
      } else {
        value = value + Math.floor(100 / _.size(milestonesIndex));
      }
    if (
      construction &&
      _.size(milestoneProgress) > 0 &&
      !construction.nextMileStoneDate
    ) {
      value = 100;
    }
  });

  const renderCard = (isFront?: boolean) => {
    return (
      <div className="Home__panel">
        <span
          className="Home__panel-btn"
          onClick={() => {
            setFlip(!flip);
          }}
        />
        <span className="Home__panel-flow-btn" onClick={openModal} />
        <h1>{construction?.house_name}</h1>
        <p className="text-date">
          {construction &&
            construction.contracted &&
            yeardateStringJp(construction.nextMileStoneDate)}
        </p>
        <p className="text-milestone">
          {construction?.contracted &&
            convertMileStoneLabel(construction?.nextMileStoneShort)}
        </p>
        <div className="Home__hero-count">
          <p>あと</p>
          <p className="Home__hero-count-value">
            {construction?.contracted && construction?.nextMileStoneLeftDays}
          </p>
          <p>日</p>
        </div>
        <div
          style={{ textAlign: 'center' }}
          className={
            construction?.ignoreVersionMaster && isFront ? 'nonDisplay' : ''
          }
        >
          {isFront ? (
            <Circle
              bgColor="#F9B8B6"
              pgColor="#FF0000"
              value={construction?.checkItemProgress}
              size="120px"
              labelText="確認事項"
            />
          ) : (
            <Circle
              bgColor="#CFE2F4"
              pgColor="#0092ff"
              value={construction?.koujiProgress}
              size="120px"
              labelText="工事進捗"
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="Home__hero">
      <FlipCard
        width="300px"
        height="290px"
        flip={flip}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        front={renderCard(!construction?.koujiProcess)}
        back={renderCard(construction?.koujiProcess)}
        isContracted={construction.contracted}
      />
    </div>
  );
}
