import React, { useState } from 'react';
const defaultProps = {};

type Props = {
  handleSearchedMessageData: (searchByEnter: boolean, query: string) => void;
  handleSetSearchKeywords: (keywords: string) => void;
  queryValue: string;
  setQueryValue: (value: string) => void;
};

export default function SearchMessagesForm(props: Props) {
  const {
    handleSearchedMessageData,
    handleSetSearchKeywords,
    queryValue,
    setQueryValue,
  } = props;

  const [showHistoryQueries, setShowHistoryQueries] = useState(false);
  const [searchHistoryQueries, setSearchHistoryQueries] = useState(['']);
  const SEARCH_QUERY_KEY = 'historyMessageSearch';
  const TIMEOUT_MSECONDS = 200;

  const onFocus = () => {
    setShowHistoryQueries(true);
    fetchQueries();
  };

  // LocalStorageから履歴を取得し、セット。
  const fetchQueries = () => {
    const historyQueries = localStorage.getItem(SEARCH_QUERY_KEY);
    if (historyQueries) {
      setSearchHistoryQueries(JSON.parse(historyQueries).reverse());
    }
  };

  const onSubmit = (searchByEnter: boolean, keywords: string) => {
    handleSearchedMessageData(searchByEnter, keywords);
    hideHistory();
    if (queryValue !== '') {
      saveQuery();
      fetchQueries();
    }
  };

  const saveQuery = () => {
    // 検索フォームの履歴表示
    const historyQueries = localStorage.getItem(SEARCH_QUERY_KEY);
    // let searchHistoryQueries = [];
    if (historyQueries !== null) {
      setSearchHistoryQueries(JSON.parse(historyQueries));
      // 検索ワード重複の対応
      if (searchHistoryQueries.includes(queryValue)) {
        const DuplicationQuery = searchHistoryQueries.indexOf(queryValue);
        searchHistoryQueries.splice(DuplicationQuery, 1);
      }
      // 検索履歴は5件まで保存
      if (searchHistoryQueries.length > 4) {
        searchHistoryQueries.shift();
      }
    }
    searchHistoryQueries.push(queryValue);
    localStorage.setItem(
      SEARCH_QUERY_KEY,
      JSON.stringify(searchHistoryQueries)
    );
  };

  const hideHistoryTimer = () => {
    setTimeout(hideHistory, TIMEOUT_MSECONDS);
  };

  const hideHistory = () => {
    setShowHistoryQueries(false);
  };

  return (
    <div className="Search__filter">
      <form>
        <div className="Search__filter-search">
          <input
            type="search"
            value={queryValue}
            placeholder="投稿者名、本文などを入力"
            onFocus={() => onFocus()}
            onBlur={() => hideHistoryTimer()}
            onChange={(e) => {
              handleSetSearchKeywords(e.target.value);
              setQueryValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onSubmit(true, queryValue);
              }
            }}
            maxLength={255}
          />
          <ul className="Search__filter-search-history">
            {showHistoryQueries &&
              searchHistoryQueries.map((historyQuery) => {
                return (
                  <li
                    key={historyQuery}
                    data-value={historyQuery}
                    onClick={() => {
                      if (historyQuery !== null) {
                        setQueryValue(historyQuery);
                        handleSetSearchKeywords(historyQuery);
                        onSubmit(false, historyQuery);
                      }
                    }}
                  >
                    {historyQuery}
                  </li>
                );
              })}
          </ul>
        </div>
      </form>
    </div>
  );
}
SearchMessagesForm.defaultProps = defaultProps;
