import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../sass/object/component/_login.scss';
import { format } from 'date-fns';

type Props = {
  children?: ReactNode;
};

export default function LoginTop(props: Props) {
  const navigate = useNavigate();
  const ToMitsuiHomeClub = () => {
    localStorage.setItem('whichAuth', 'MitsuiHomeClub');
    return navigate('/');
  };
  const ToSalesforce = () => {
    localStorage.setItem('whichAuth', 'Salesforce');
    return navigate('/');
  };
  const thisYear = format(new Date(), 'yyyy');

  return (
    <div className="Login">
      <div className="Login__header">
        <div className="Login__header__brand-logo-left">
          <h1>
            <img src="/assets/img/common/logo_nav.svg" alt="住まいづくりナビ" />
          </h1>
        </div>
        <div className="Login__header__brand-logo-right">
          <a
            className="mh-copyright__logo-corporate"
            href="https://www.mitsuihome.co.jp/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/assets/img/common/logo_180.svg" alt="三井ホーム" />
          </a>
        </div>
      </div>
      <div className="Login__button-container">
        <button className="Login__button" onClick={ToMitsuiHomeClub}>
          三井ホーム倶楽部でログイン
        </button>
        <span>（お手持ちのIDが数字のみの方はこちら）</span>
        <button className="Login__button" onClick={ToSalesforce}>
          MITSUI HOME IDでログイン
        </button>
        <span>（お手持ちのIDがメールアドレスの方はこちら）</span>
      </div>
      <div className="Login__copyright">
        <div className="Login__copyright__privacy">
          <a
            className="Login__copyright__privacy-row"
            href="https://www.mitsuihome.co.jp/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            個人情報保護方針
          </a>
          <a
            className="Login__copyright__privacy-row"
            href="https://www.mitsuihome.co.jp/privacy/#h_toriatukai"
            target="_blank"
            rel="noreferrer"
          >
            個人情報の取扱いについて
          </a>
          <a
            className="Login__copyright__privacy-row"
            href="https://www.mitsuihome.co.jp/privacy/#h_tokuteikojin"
            target="_blank"
            rel="noreferrer"
          >
            特定個人情報基本方針
          </a>
          <a className="Login__copyright__privacy-row" href="/attention">
            サイト利用上の注意
          </a>
          <a
            className="Login__copyright__privacy-row"
            href="https://www.mitsuihome.co.jp/privacy/cookie_accesslog.html"
            target="_blank"
            rel="noreferrer"
          >
            Cookieおよびアクセスログについて
          </a>
        </div>
      </div>
      <div className="Login__brand-logo-footer">
        <a
          className="mh-copyright__logo-group"
          href="https://www.mitsuifudosan.co.jp/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/img/common/mitsuifudosan_logo.svg"
            alt="三井不動産グループ"
          />
        </a>
      </div>
      <div className="Login__copyright">
        <p>©1997-{thisYear} MITSUIHOME CO.,LTD.</p>
      </div>
    </div>
  );
}
