import React, { ReactNode, CSSProperties } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

const Front = styled.div`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform: rotateY(0deg);
`;

const Back = styled.div`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
`;

type FlipperType = {
  state: string;
};

const Flipper = styled.div<FlipperType>`
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  transform: ${({ state }) => {
    switch (state) {
      case 'entering':
        return 'rotateY(-180deg)';
      case 'entered':
        return 'rotateY(-180deg)';
      case 'exiting':
        return 'rotateY(-0deg)';
      case 'exited':
        return 'rotateY(0)';
    }
  }};
`;

type ContainerProps = {
  width: string;
  height: string;
};

const Container = styled.div<ContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  perspective: 1000px;
`;

type FlipProps = {
  width: string;
  height: string;
  style?: CSSProperties;
  flip: boolean;
  front: ReactNode;
  back: ReactNode;
  isContracted: boolean;
};

const FlipCard = (props: FlipProps) => {
  const { flip, front, back, width, height, style, isContracted } = props;
  return (
    <Transition appear in={flip} timeout={550}>
      {(state) => (
        <Container width={width} height={height} style={style}>
          {isContracted ? (
            <Flipper state={state}>
              <Front>{front}</Front>
              <Back>{back}</Back>
            </Flipper>
          ) : (
            <></>
          )}
        </Container>
      )}
    </Transition>
  );
};

export default FlipCard;
