import React from 'react';
import Theme from './theme';
import MyRoot from 'features/location/Root';
import AppRouter from 'router';
import { Outlet } from 'react-router-dom';

export default function App() {
  return (
    <div className="App">
      <Theme>
        <MyRoot>
          <AppRouter />
        </MyRoot>
      </Theme>
    </div>
  );
}
