import SsoFromSalesforce from '../SsoFromSalesforce';

const defaultProps = {
  prevUrl: '/',
  redirectUrl: '',
} as Partial<Props>;

type Props = {
  children?: any;
  redirectUrl: string;
  prevUrl: string;
};

const AuthWithSalesforce = (props: Props) => (
  <>
    <SsoFromSalesforce
      redirectUrl={props.redirectUrl}
      prevUrl={`${process.env.REACT_APP_WEB_HOST}${props.prevUrl}`}
    />
  </>
);

AuthWithSalesforce.defaultProps = defaultProps;

export default AuthWithSalesforce;
