export default function Attention() {
  return (
    <div className="Attention">
      <div className="Attention__back">
        <a className="Attention__back-link" href="/">
          戻る
        </a>
      </div>
      <div className="Attention__title">
        <h1>サイト利用上の注意</h1>
        <p>
          本ウェブサイトをご利用されるに際し、以下の利用条件をよくお読みください。
        </p>
      </div>

      <div className="Attention__content">
        <div className="Attention__block">
          <h2>サイト利用上の注意</h2>
          <div className="Attention__block-content">
            <ul>
              <li>
                本ウェブサイトは、三井ホーム株式会社（以下、「当社」といいます）が運営・管理しています。
              </li>
              <li>
                当社ウェブサイトおよび三井不動産株式会社グループ会社ウェブサイトをご利用されるに際し、以下の利用条件をよくお読みいただき、ご同意のうえ本ウェブサイトをご利用下さるよう
                お願い申しあげます。
              </li>
              <li>
                また、当社ウェブサイト、および三井不動産株式会社グループ会社ウェブサイトにおいて別途利用条件を定めている場合には、各利用条件についてもよくお読みいただき、ご同意のうえご利用いただくようお願い申しあげます。お客様が当社ウェブサイトをご利用いただいた場合には、すべての利用条件にご同意いただいたものとさせていただきますのでご了承願います。
              </li>
              <li>
                なお、当社ウェブサイト、および三井不動産株式会社グループ会社ウェブサイトは、利用条件を必要に応じて変更することがありますので、ご利用の都度、内容をご確認いただきますようお願い申しあげます。
              </li>
            </ul>
          </div>
        </div>
        <div className="Attention__block">
          <h2>クッキー（Cookie）とアクセスログについて</h2>
          <div className="Attention__block-content">
            <ul>
              <li>
                クッキー（Cookie）とアクセスログについては、三井ホーム株式会社の規定に従います。
                <br />
                三井ホームウェブサイト
                <a
                  href="https://www.mitsuihome.co.jp/privacy/cookie_accesslog.html"
                  target="_blank"
                  rel="noopenner noreferrer"
                >
                  「Cookie（クッキー）およびアクセスログについて」
                </a>
                をご覧ください。
              </li>
            </ul>
          </div>
        </div>
        <div className="Attention__block">
          <h2>著作権などについて</h2>
          <div className="Attention__block-content">
            <ul>
              <li>
                当サイト内に掲載されている情報、デザイン、レイアウト、ロゴマーク、キャラクター、商標等に関しては、当社または当社にその利用を認めた権利者が、著作権等の知的財産権、使用権その他の権利を有しています。お客様は、これらの権利を侵害しない範囲でのみ、当サイトをご利用いただくことができます。権利者の許可無く、複製、転用等されることの無いようお願い申し上げます。
              </li>
              <li>
                当社がお客様に発信したメール等についての著作権は当社に帰属しており、当社に無断で他のサイトや印刷物等へ転用することはできません。
              </li>
            </ul>
          </div>
        </div>
        <div className="Attention__block">
          <h2>リンクについて</h2>
          <div className="Attention__block-content">
            <ul>
              <li>
                当サイトへのリンクをご希望の場合、必ず事前にリンク元URLと当サイト内のリンク先URLおよびご連絡先を当社までお知らせ下さい。ただし、公序良俗に反する内容、当社等を誹謗中傷する内容を含むサイト等、当社がリンクを不適切と判断する場合には、リンクをお断りすることがございます。
              </li>
            </ul>
          </div>
        </div>
        <div className="Attention__block">
          <h2>免責事項など</h2>
          <div className="Attention__block-content">
            <ul>
              <li>
                当サイトにはご利用者の便宜のために他サイトへのリンクが設定されていますが、これらのサイトの利用に伴う損害・不利益等については一切責任を負いかねますので、あらかじめご了承下さい。
              </li>
              <li>
                当サイトで提供しております各種情報につきましては、お客様に不測の損害・不利益等が発生しないよう適切に努力し、最新かつ正確な情報を掲載するよう注意を払っておりますが、その内容の完全性、正確性、有用性等について保証をするものではありません。従いまして当社は、お客様が当該情報に基づいて起こされた行動によって生じた損害・不利益等についていかなる責任も負いかねますので、あらかじめご了承下さい。
              </li>
              <li>
                お客様からのメールや資料請求等が、インターネット上の不具合・事故等により当社に着信しなかった場合、当社はそれについていかなる責任も負いかねますので、あらかじめご了承下さい。
              </li>
              <li>
                また当社は、当サイトのご利用に起因し、ソフトウエア、ハードウエア上に生じた事故、コンピューターウイルスによる汚染、データの滅失・毀損等の一切の損害・不利益等、およびご利用のお客様間またはお客様と第三者間において生じたトラブル等による損害・不利益等についていかなる責任も負いかねますので、あらかじめご了承下さい。
              </li>
              <li>
                当社は、火災、停電、地震等の天災その他の事由によるシステム障害の発生、およびシステム保守の必要上、当サイトの全部または一部の提供の中断または中止が必要であると判断した場合には、事前に通知することなく当サイトの全部または一部の提供を中断または中止する場合がございます。この中断または中止により生じた損害について、当社はいかなる責任も負いかねますので、あらかじめご了承下さい。
              </li>
              <li>
                当サイトは、ご利用の皆様に対して事前にお知らせをすることなく、サービス内容の追加や部分的変更、廃止をさせていただくことがありますので、あらかじめご了承下さい。
              </li>
            </ul>
          </div>
        </div>
        <div className="Attention__block">
          <h2>利用環境について</h2>
          <div className="Attention__block-content">
            <div className="Attention__sub-block">
              <h3>■SSLについて</h3>
              <ul>
                <li>
                  当サイトでは個人情報をご提供いただく際に、SSL（Secure Sockets
                  Layer）による暗号化通信をご利用いただくことができます。尚、SSLのご利用にはSSL対応ブラウザが必要となります。但し、SSL対応ブラウザでもその設定やインターネット接続環境等により、SSLが利用できない場合がありますので、ご注意ください。
                </li>
              </ul>
            </div>
            <div className="Attention__sub-block">
              <h3>■OS・ブラウザの種類・バージョン</h3>
              <p>
                ※SSL非対応ブラウザや古いバージョンのブラウザではSSL対応ページを表示できない可能性がありますので、最新バージョンのブラウザでのご利用をお勧めいたします。
              </p>
              <ul>
                <li>
                  Windows
                  <br />
                  ブラウザ：Firefox（最新版）、Google Chrome（最新版）
                  <br />
                  OS：Windows11、Windows10、Windows8.1
                </li>
                <li>
                  Macintosh
                  <br />
                  ブラウザ：Safari（最新版）
                  <br />
                  OS：Mac OS 10.15以上
                </li>
                <li>
                  Windows
                  <br />
                  タブレット端末 iOS 13以上 標準ブラウザ
                  <br />
                  Android 8.0以上 標準ブラウザ
                </li>
              </ul>
            </div>
            <div className="Attention__sub-block">
              <h3>■ブラウザの設定</h3>
              <ul>
                <li>Cookie：有効</li>
                <li>JavaScript：受け入れる</li>
                <li>CSS：有効</li>
              </ul>
            </div>
            <div className="Attention__sub-block">
              <h3>■Java Scriptについて</h3>
              <ul>
                <li>
                  当サイトではJavaScriptを使用しているページがあります。お使いのブラウザでJavaScriptを無効にされている場合、正しく機能しない、もしくは正しく表示されないことがあります。ご利用の際は、ブラウザの設定で
                  JavaScriptを有効にしてください。
                </li>
              </ul>
            </div>
            <div className="Attention__sub-block">
              <h3>■スタイルシートについて</h3>
              <ul>
                <li>
                  当サイトではスタイルシート（CSS）を使用しております。お使いのブラウザでスタイルシートを無効にされている場合、正しく表示されないことがあります。ご利用の際は、ブラウザの設定でスタイルシートを有効にしてください。
                </li>
              </ul>
            </div>
            <div className="Attention__sub-block">
              <h3>■プラグインについて</h3>
              <ul>
                <li>
                  当サイト内の一部ページ・ファイル閲覧の際に必要となります。お使いのパソコンにインストールされていない場合、ご本人の責任において、各メーカーサイトからダウンロードしてご利用下さい。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
