import React, { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, useTypedSelector } from '../../../App/rootReducer';
import { FileParams, QuotableType } from '../../../api/message';
import { resetNotificationCount } from '../../user/userSlice';
import {
  createMessage,
  updateMessage,
  deleteFile,
  fetchAdditionalMessages,
  deleteMessage,
  searchMessages,
  setPinnedMessage,
  resetPinnedMessage,
  createMessageReaction,
  deleteMessageReaction,
  resetFocusedMessageNum,
} from '../messageSlice';
import {
  createPinnedMessage,
  deletePinnedMessage,
} from '../../pinnedMessage/pinnedMessageSlice';
import * as Sentry from '@sentry/browser';
import Messages from '../../../components/pages/Messages';

type Props = {
  children?: ReactNode;
};

export default function MessageWithRedux(props: Props) {
  const dispatch = useDispatch<any>();
  const construction = useSelector(
    (state: RootState) => state.user.user.login_construction
  );
  // TODO: as any良くない
  const messages = useSelector(
    (state: RootState) => state.message.messages
  ) as any;
  const messageCount = useSelector(
    (state: RootState) => state.message.messageCount
  ) as number;
  const loadedPages = useSelector(
    (state: RootState) => state.message.loadedPages
  );
  const getMessagesNum = useSelector(
    (state: RootState) => state.message.getMessagesNum
  ) as any;
  const fetching = useSelector((state: RootState) => state.message.fetching);
  const currentTFilterType = useTypedSelector(
    (state: RootState) => state.message.selectedTab
  );
  const pinnedMessage = useSelector((state: RootState) => state.pinnedMessage);
  const user = useSelector((state: RootState) => state.user.user);
  const searchedQuery = useSelector(
    (state: RootState) => state.message.searchedQuery
  );
  const focusedMessageNum = useSelector(
    (state: RootState) => state.message.focusedMessageNum
  );

  useEffect(() => {
    async function f() {
      try {
        await dispatch(searchMessages(1, 'posted', ''));
        // 通知リセット
        await dispatch(resetNotificationCount());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  useEffect(() => {
    async function f() {
      try {
        if (pinnedMessage.pinnedMessage) {
          await dispatch(setPinnedMessage(pinnedMessage.pinnedMessage));
        }
      } catch (error) {
        throw error;
      }
    }
    f();
  }, [pinnedMessage.pinnedMessage]);

  // 初期作成
  const onSubmitMessage = (
    body: string,
    files: object[],
    status: string,
    scheduledAt: Date | null,
    currentTab: string,
    quotableId?: number,
    quotableType?: QuotableType
  ) => {
    async function f() {
      const formattedFiles: FileParams[] = [];

      // TODO: anyを修正
      files.forEach((file: any) => {
        if (file.file) {
          formattedFiles.push({
            name: file.file.name,
            url: file.file.type,
            image: file.file,
          });
        }
      });

      try {
        await dispatch(
          createMessage(
            body,
            formattedFiles,
            scheduledAt,
            status,
            quotableId,
            quotableType
          )
        );
        await dispatch(searchMessages(1, currentTab, ''));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  // 下書きから更新
  const onUpdateMessage = async (
    messageId: number,
    body: string,
    files: object[],
    status: string,
    scheduledAt: Date | null,
    currentTab: string
  ) => {
    const formattedFiles: FileParams[] = [];

    try {
      files.forEach((file: any) => {
        if (file) {
          formattedFiles.push({
            fileId: file.fileId ?? '',
            name: file.file?.name ? file.file.name : '',
            url: file.file?.type,
            image: file.file ?? '',
          });
        }
      });
      await dispatch(
        updateMessage(messageId, body, formattedFiles, scheduledAt, status)
      );
      await dispatch(searchMessages(1, currentTab, ''));
    } catch (error) {
      throw error;
    }
  };

  const onDeleteFile = (fileId: number, messageId: number) => {
    async function f() {
      try {
        await dispatch(deleteFile(fileId, messageId));
        await dispatch(searchMessages(1, currentTFilterType, ''));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  const onDeleteMessage = (messageId: number, currentTab: string) => {
    async function f() {
      try {
        await dispatch(deleteMessage(messageId));
        await dispatch(searchMessages(1, currentTab, ''));
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  const onFetchNextPage = (filterType: string, searchKeywords?: string) => {
    async function f() {
      const nextPage = loadedPages.end + 1;
      try {
        await dispatch(
          fetchAdditionalMessages(nextPage, filterType, searchKeywords)
        );
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  const onFetchPreviousPage = async (
    filterType: string,
    searchKeywords?: string
  ) => {
    if (loadedPages.start < 2) {
      return;
    }
    const previousPage = loadedPages.start - 1;
    await dispatch(
      fetchAdditionalMessages(previousPage, filterType, searchKeywords)
    );
  };

  const onChangeSearchMessages = (
    filterType: string,
    searchKeywords?: string,
    serialNumber?: number
  ) => {
    dispatch(searchMessages(1, filterType, searchKeywords, serialNumber));
  };

  const handleCreatePinnedMessage = async (messageId: number) => {
    async function f() {
      try {
        await dispatch(createPinnedMessage(messageId));
      } catch (error) {
        throw error;
      }
    }
    f();
  };

  const handleDeletePinnedMessage = async (
    messageId: number,
    pinnedMessageId: number
  ) => {
    async function f() {
      try {
        await dispatch(deletePinnedMessage(pinnedMessageId));
        await dispatch(resetPinnedMessage(messageId));
        if (currentTFilterType !== 'posted') {
          await dispatch(searchMessages(1, currentTFilterType, ''));
        }
      } catch (error) {
        throw error;
      }
    }
    f();
  };

  const handleCreateMessageReaction = async (
    messageId: number,
    emojiCode: string
  ) => {
    await dispatch(createMessageReaction(messageId, emojiCode));
  };

  const handleDeleteMessageReaction = async (
    messageId: number,
    reactionId: number
  ) => {
    await dispatch(deleteMessageReaction(messageId, reactionId));
  };

  const onResetFocusedMessageNum = () => {
    dispatch(resetFocusedMessageNum());
  };

  return (
    <>
      <Messages
        messages={messages}
        messageCount={messageCount}
        getMessagesNum={getMessagesNum}
        fetching={fetching}
        user={user}
        searchedQuery={searchedQuery}
        onDelete={(fileId, messageId) => onDeleteFile(fileId, messageId)}
        onDeleteMessage={(messageId, currentTab) =>
          onDeleteMessage(messageId, currentTab)
        }
        onSubmit={onSubmitMessage}
        onUpdate={onUpdateMessage}
        fetchNextPage={onFetchNextPage}
        fetchPreviousPage={onFetchPreviousPage}
        handedOver4monthsAgo={construction!.handed_over_4months_ago}
        onChangeSearchMessages={onChangeSearchMessages}
        handleCreatePinnedMessage={handleCreatePinnedMessage}
        handleDeletePinnedMessage={handleDeletePinnedMessage}
        handleCreateMessageReaction={handleCreateMessageReaction}
        handleDeleteMessageReaction={handleDeleteMessageReaction}
        focusedMessageNum={focusedMessageNum}
        resetFocusedMessageNum={onResetFocusedMessageNum}
      />
    </>
  );
}
