import { Box, ClickAwayListener } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';

const mediaAllPc = 'all and (min-width: 769px)';
const mediaScreenSp = 'screen and (max-width: 768px)';

const MenuButton = styled.div`
  position: absolute;
  z-index: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;

  img {
    @media ${mediaAllPc} {
      width: 2.6rem;
    }

    @media ${mediaScreenSp} {
      width: 2.8rem;
    }
  }

  @media ${mediaAllPc} {
    width: 3.6rem;
    height: 3.6rem;
    top: 1rem;
    right: 4.5rem;
  }

  @media ${mediaScreenSp} {
    width: 2.8rem;
    height: 2.8rem;
    top: 1.3rem;
    right: 4.8rem;
  }
`;

const MenuBalloon = styled.div<{ $isOpen: boolean }>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #000;
  color: #fff;
  border-radius: 0.5rem;
  z-index: 300;

  @media ${mediaAllPc} {
    width: 15rem;
    top: 5.7rem;
    right: 1.2rem;
  }

  @media ${mediaScreenSp} {
    width: 12rem;
    top: 5.4rem;
    right: 3.5rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: -2.2rem;
    left: 8.2rem;
    border-top: 12px solid transparent;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: 12px solid #000;
    pointer-events: none;
  }
`;

const BalloonItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;
  padding-top: 1.2rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  padding-bottom: 1.1rem;
  font-size: 1.8rem;
  cursor: pointer;

  @media ${mediaScreenSp} {
    font-size: 1.3rem;
    margin: 0;
  }

  img {
    width: 1.5rem;
    height: 1.7rem;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

interface Props {
  onClickDeleteButton: () => void;
  onClickShowInAllItemsButton: () => void;
  onClickQuoteButton: () => void;
  displayDeleteButton: boolean;
  displayShowInAllItemsButton: boolean;
  displayQuoteButton: boolean;
}

export const MessageMenuButton = (props: Props) => {
  const {
    onClickDeleteButton,
    onClickShowInAllItemsButton,
    onClickQuoteButton,
    displayDeleteButton,
    displayShowInAllItemsButton,
    displayQuoteButton,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClickMenuButton = () => {
    setIsOpen(true);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const handleClickDeleteButton = () => {
    setIsOpen(false);
    onClickDeleteButton();
  };

  const handleClickShowInAllItemsButton = () => {
    setIsOpen(false);
    onClickShowInAllItemsButton();
  };

  const handleClickQuoteButton = () => {
    setIsOpen(false);
    onClickQuoteButton();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <MenuButton onClick={handleClickMenuButton}>
          <img src="/assets/img/common/icon/setting_menu.svg" alt="" />
        </MenuButton>
        <MenuBalloon $isOpen={isOpen}>
          {displayDeleteButton && (
            <BalloonItem onClick={handleClickDeleteButton}>
              <span>削除</span>
              <img src="/assets/img/common/icon/trash.svg" alt="" />
            </BalloonItem>
          )}
          {displayShowInAllItemsButton && (
            <BalloonItem onClick={handleClickShowInAllItemsButton}>
              <span>一覧で表示</span>
              <img src="/assets/img/common/icon/arrow_right.svg" alt="" />
            </BalloonItem>
          )}
          {displayQuoteButton && (
            <BalloonItem onClick={handleClickQuoteButton}>
              <span>引用する</span>
              <img src="/assets/img/common/icon/edit_icon.svg" alt="" />
            </BalloonItem>
          )}
        </MenuBalloon>
      </Box>
    </ClickAwayListener>
  );
};

export default MessageMenuButton;
