export default function Welcome() {
  return (
    // デザインベース：src/components/molecules/HomeSlideItem/index.tsx
    <div className="Home__slide-item -active">
      <div className="Home__slide">
        <div className="Home__slide-item -active">
          <div>
            <div className="Home__article">
              <div className="Home__inner">
                <div className="Home__article-body">
                  <p className="Home__article-lead">
                    住まいづくりナビへようこそ
                  </p>
                  <p>
                    理想のお住まいを一緒につくっていくため打ち合わせのご協力をお願いいたします。
                  </p>
                  <p className="Home__article-note">
                    ※一部地域、物件は住まいづくりナビを利用できない場合がございますので、担当者にご確認ください。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
