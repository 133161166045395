import React, { useState, ReactNode } from 'react';
import { ArticleModel } from '../../../api/model/article';
import Skeleton from 'components/atoms/Skeleton';
import Nav from 'components/molecules/Nav';
import AlertDialog from '../../atoms/AlertDialog';
import ColumnReviewModal from 'components/organisms/ColumnReviewModal';
import { ConstructionModel } from 'api/model/construction';
import PostMessageModal from 'components/organisms/PostMessageModal';
import Circular from '../../atoms/Circular';
import { useNavigate } from 'react-router-dom';
import { QuotableType } from 'api/message';

const defaultProps = {
  fetching: true,
};

type Props = {
  children?: ReactNode;
  article: ArticleModel;
  fetching: boolean;
  materialCompletedLoading: boolean;
  handleToggleConfirmation: (householdId: number | null) => void;
  handleCompletion: () => void;
  handedOver4monthsAgo: boolean;
  construction?: ConstructionModel;
  onSubmitArticleReview: (
    reviewTargetId: number,
    comment: string | null,
    constructionId: number
  ) => void;
  onSubmitPostMessage: (
    body: string,
    quotableId: number,
    quotableType: QuotableType
  ) => Promise<void>;
} & typeof defaultProps;

export default function ArticleDetail(props: Props) {
  const {
    article,
    fetching,
    materialCompletedLoading,
    construction,
    handleToggleConfirmation,
    handleCompletion,
    handedOver4monthsAgo,
    onSubmitArticleReview,
    onSubmitPostMessage,
  } = props;
  const navigate = useNavigate();

  const agenda = JSON.parse(localStorage.getItem('agenda') || 'null');
  const [openColumnReviewModal, setOpenColumnReviewModal] = useState(false);
  const [openPostMessageModal, setOpenPostMessageModal] = useState(false);
  const [openHandedOveredAlert, setOpenHandedOveredAlert] = useState(false);

  //現在の記事のagendaArticles配列内のインデックスを取得
  const currentArticleIndex =
    agenda && agenda.articles && article
      ? agenda.articles.findIndex((a: { id: number }) => a.id === article.id)
      : -1;

  const handleCloseAlert = () => {
    setOpenHandedOveredAlert(false);
  };

  const handleConfirmedClick = (
    householdId: number | null,
    ignored: boolean
  ) => {
    if (!ignored) {
      handleToggleConfirmation(householdId);
    }
  };

  const handlePrevButtonClick = () => {
    navigate(`/articles/${agenda.articles[currentArticleIndex - 1].id}`, {
      replace: true,
    });
  };

  const handleNextButtonClick = () => {
    navigate(`/articles/${agenda.articles[currentArticleIndex + 1].id}`, {
      replace: true,
    });
  };

  const confirmationStatus = (ignored: boolean, checked: boolean) => {
    if (ignored) {
      return '確認不要です';
    } else if (checked) {
      return '確認済です';
    } else {
      return '確認しました';
    }
  };

  const handleCheckBoxChange = () => {
    if (!article.completed) {
      handleCompletion();
    }
  };

  const handleClassName = (color: string) => {
    switch (color) {
      case '#c97179':
        return '-red';
      case '#e6c671':
        return '-yellow';
      case '#9cc06c':
        return '-green';
      case '#7cb5d1':
        return '-blue';
      case '#7495c6':
        return '-purple';
    }
  };

  const handlePostMessage = async (body: string, quotableId: number) => {
    onSubmitPostMessage(body, quotableId, 'Article');
    setOpenPostMessageModal(false);
  };

  return (
    <>
      <AlertDialog
        title={'編集不可'}
        description={'お引渡しから4ヶ月が経過しているため、編集はできません。'}
        open={openHandedOveredAlert}
        onAgree={() => handleCloseAlert()}
        onClose={() => handleCloseAlert()}
        singleButton
      />
      <Nav title={article?.name} titleClass="Column__title" />
      <div id="pageTop" className="container -with-bar Column__wrapper">
        {fetching && <Skeleton type="articleDetail" />}
        {!fetching && (
          <div className="Column__box">
            <div
              className="Column__article"
              dangerouslySetInnerHTML={{ __html: article?.body }}
            />
            <div className="Column__approval">
              {/* 部資材非表示邸名 || (部資材表示邸名 && 部資材以外コラム) は「確認しました」ボタン */}
              {!construction?.is_display_material_articles ||
              (construction?.is_display_material_articles &&
                !article.is_material) ? (
                <>
                  <div>
                    {article.households.length !== 1 ? (
                      // 多世帯の場合の確認ボタン
                      <div className="Column__approval-inner -household">
                        {article.households.map((household, index) => (
                          <div className="Column__approval-item" key={index}>
                            <div
                              className={`Column__approval-label ${handleClassName(
                                household.color
                              )}`}
                            >
                              <span>{household.name}</span>
                            </div>
                            <span
                              className={`Column__approval-btn ${
                                household.checked ? '-checked' : ''
                              }`}
                              onClick={() =>
                                handedOver4monthsAgo
                                  ? setOpenHandedOveredAlert(true)
                                  : handleConfirmedClick(
                                      index === 0 ? null : household.id,
                                      household.ignored
                                    )
                              }
                            >
                              {confirmationStatus(
                                household.ignored,
                                household.checked
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      // 多世帯じゃない場合の確認ボタン
                      <div className="Column__approval-inner">
                        {article.households.map((household, index) => (
                          <div className="Column__approval-item" key={index}>
                            <span
                              className={`Column__approval-btn ${
                                household.checked ? '-checked' : ''
                              }`}
                              onClick={() =>
                                handedOver4monthsAgo
                                  ? setOpenHandedOveredAlert(true)
                                  : handleConfirmedClick(
                                      index === 0 ? null : household.id,
                                      household.ignored
                                    )
                              }
                            >
                              {confirmationStatus(
                                household.ignored,
                                household.checked
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="button-list">
                    <span
                      className={`prev-button 
                  ${
                    !(agenda?.articles && currentArticleIndex > 0) &&
                    'invisible'
                  }`}
                      onClick={handlePrevButtonClick}
                    />
                    <span
                      className="column-request-button"
                      onClick={() => setOpenColumnReviewModal(true)}
                    />
                    <span
                      className={`next-button 
                  ${
                    !(
                      agenda?.articles &&
                      currentArticleIndex < agenda.articles.length - 1
                    ) && 'invisible'
                  }`}
                      onClick={handleNextButtonClick}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div className="Column__completion-inner">
                      {/* {やることは世帯管理しないので部資材コラム確認も世帯管理しない} */}
                      <div className="Column__question-item">
                        <span className="Column__question-text">
                          内容をご確認いただき、ご不明な点が無ければチェックをお願いします。
                        </span>
                        <span className="Column__question-text">
                          ご不明な点は
                          <span
                            className="Column__question-link"
                            onClick={() => setOpenPostMessageModal(true)}
                          >
                            こちら
                          </span>
                          から担当者にご質問ください。
                        </span>
                      </div>
                      {materialCompletedLoading && (
                        <Circular size={30} disableShrink={true} />
                      )}
                      {!materialCompletedLoading && (
                        <div className="Column__completion-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              disabled={article.completed}
                              checked={article.completed}
                              onChange={() =>
                                handedOver4monthsAgo
                                  ? setOpenHandedOveredAlert(true)
                                  : handleCheckBoxChange()
                              }
                            />
                            <span>理解しました</span>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="button-list">
                    <span
                      className={`prev-button 
                  ${
                    !(agenda?.articles && currentArticleIndex > 0) &&
                    'invisible'
                  }`}
                      onClick={handlePrevButtonClick}
                    />
                    {!article.is_material && (
                      <span
                        className="column-request-button"
                        onClick={() => setOpenColumnReviewModal(true)}
                      />
                    )}
                    <span
                      className={`next-button 
                  ${
                    !(
                      agenda?.articles &&
                      currentArticleIndex < agenda.articles.length - 1
                    ) && 'invisible'
                  }`}
                      onClick={handleNextButtonClick}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <ColumnReviewModal
        article={article}
        open={openColumnReviewModal}
        constructionId={construction?.id}
        setOpenColumnReviewModal={setOpenColumnReviewModal}
        onSubmitArticleReview={(
          reviewTargetId: number,
          comment: string | null,
          constructionId: number
        ) => onSubmitArticleReview(reviewTargetId, comment, constructionId)}
      />
      <PostMessageModal
        open={openPostMessageModal}
        onClose={() => {
          setOpenPostMessageModal(false);
        }}
        modalTitle="担当者に質問"
        id={article?.id}
        handlePostMessage={handlePostMessage}
        inputHint="この説明についての質問内容を入力してください"
      />
    </>
  );
}

ArticleDetail.defaultProps = defaultProps;
