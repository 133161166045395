import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom';

// ページを 作成したら import すること。
import Home from '../features/appointment/Home';
import Files from '../features/dataFile/Files';
import IdSheet from '../features/idSheet/IdSheet';
import Messages from '../features/message/Message';
import ScheduleArticles from '../features/agenda/ScheduleArticles';
import Schedule from '../features/appointment/Schedules';
import Furniture from '../features/furniture/furniture';
import ArticleDetail from '../features/article/ArticleDetail';
import Articles from '../features/designStep/Articles';
import AppointmentDetail from '../features/appointment/AppointmentDetail';
import NotificationSetting from '../features/notificationSetting/notificationSetting';
import Tasks from '../features/task/Tasks';
import Materials from '../features/material/Materials';
import Error from '../components/pages/Error';
import Tutorial from 'components/pages/Tutorials';
import Keep from '../components/pages/Keeps/index';
import ThreeDViewer from 'components/pages/ThreeDViewer';
import MitsuiHomeClub from 'features/auth/Auth';
import Salesforce from 'features/auth/Salesforce';
import LoginTop from 'components/pages/LoginTop';
import { CallbackSalesforce } from 'features/CallBack/CallBackDetail';

export default function AppRouter() {
  const location = useLocation();
  const whichAuth = localStorage.getItem('whichAuth');
  const setLoggedIn = sessionStorage.getItem('setLoggedIn');
  const Redirected = sessionStorage.getItem('setRedirected');
  const navi_token = localStorage.getItem('navi_token');
  const receivedCsrfToken = new URLSearchParams(location.search).get(
    'csrf_token'
  );

  useEffect(() => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

    if (measurementId) {
      window.gtag('config', measurementId, { page_path: location.pathname });
    }
  }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_SALESFORCE_RELEASE === 'false') {
      return localStorage.setItem('whichAuth', 'MitsuiHomeClub');
    }

    if (Redirected && !setLoggedIn) {
      if (
        (whichAuth === 'Salesforce' &&
          !location.pathname.includes('/callback_salesforce')) ||
        (whichAuth === 'MitsuiHomeClub' && navi_token && !receivedCsrfToken)
      ) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace('/login_top');
      }
    }
  }, []);

  const children = (
    <>
      <Route path="/" element={<Home />} />
      <Route path="/files" element={<Files />} />
      <Route path="/idsheet" element={<IdSheet />} />q
      <Route path="/agendas/:id" element={<ScheduleArticles />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/schedules" element={<Schedule />} />
      <Route path="/furniture" element={<Furniture />} />
      <Route path="/articles/:id" element={<ArticleDetail />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/appointments/:id" element={<AppointmentDetail />} />
      <Route path="/tutorials" element={<Tutorial />} />
      <Route path="/keeps" element={<Keep />} />
      <Route path="/three_d_viewer" element={<ThreeDViewer />} />
      <Route path="/tasks" element={<Tasks />} />
      <Route path="/materials" element={<Materials />} />
      <Route path="/notification_settings" element={<NotificationSetting />} />
      <Route path="/error" element={<Error />} />
      {/* 404 not found */}
      <Route path="*" element={<Navigate to="/error" />} />
    </>
  );

  const handleChangeAuthShowAble = () => {
    if (process.env.REACT_APP_SALESFORCE_RELEASE === 'true') {
      return (
        <>
          <Routes>
            <Route path="/login_top" element={<LoginTop />} />
            <Route
              path="/callback_salesforce"
              element={<CallbackSalesforce />}
            />
          </Routes>
          {whichAuth === 'MitsuiHomeClub' ? (
            <MitsuiHomeClub>
              <Routes>{children}</Routes>
            </MitsuiHomeClub>
          ) : whichAuth === 'Salesforce' ? (
            <Salesforce>
              <Routes>{children}</Routes>
            </Salesforce>
          ) : (
            <Navigate to="/login_top" />
          )}
        </>
      );
    } else {
      return (
        <MitsuiHomeClub>
          <Routes>{children}</Routes>
        </MitsuiHomeClub>
      );
    }
  };

  return <>{handleChangeAuthShowAble()}</>;
}
