import apiClient from './client';
import { ConstructionModel } from './model/construction';
import { UserModel } from './model/user';
import orderSelfstylingClient from './OrderSelfstylingClient';

/**
 * API 定義
 * USER
 *
 */

export type SearchParams = {
  keyword: string;
};

export type SearchRes = {
  data: {
    users: ConstructionModel[];
    total: number;
  };
};

export async function search(params: SearchParams) {
  return apiClient.get<SearchRes>(`/employee?search_keyword=${params.keyword}`);
}

export type LoginRes = {
  data: {
    user: UserModel;
    deleted_user: boolean;
  };
};

// 三井ホーム倶楽部ログイン
export async function loginFromMitsuiHomeclub() {
  return apiClient.post<LoginRes>(`/users/signins`);
}

export type ProjectContractStatusRes = {
  status: 'success' | 'error';
  contract_status: 0 | 1;
};

export type SelfStylingParams = {
  federation_id: string; // 統合認証ID
  project_id: string; // プロジェクトID
};

// プロジェクト契約状況取得（セルフスタイリングフォーオーダーAPIを叩く）
export async function fetchProjectContractStatus(params: SelfStylingParams) {
  return orderSelfstylingClient.get<ProjectContractStatusRes>(
    `/get_project_contract_status`,
    { params: params }
  );
}

export type loginFromSalesforceParams = {
  code: string;
};

export type SalesforceLoginRes = {
  data: {
    user: UserModel;
    deleted_user: boolean;
  };
};

// 統合認証：ログイン
export async function loginFromSalesforce(code: loginFromSalesforceParams) {
  return apiClient.post<SalesforceLoginRes>(`/users/salesforce_sessions`, code);
}

export type fetchDataFromSalesforceRes = {
  data: {
    user: UserModel;
    deleted_user: boolean;
  };
};

// 統合認証：セッションチェック＋工事情報取得
export async function fetchDataFromSalesforce() {
  return apiClient.get<fetchDataFromSalesforceRes>(`/users/constructions`);
}

// 統合認証：ログアウト
export async function logoutFromSalesforce() {
  return await apiClient.delete(`/users/salesforce_sessions`);
}
