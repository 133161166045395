import React, { useState, useEffect, useRef, ReactNode } from 'react';
import DOMPurify from 'dompurify';
import { MessageModel } from '../../../api/model/message';
import { DataFileModel } from '../../../api/model/dataFile';
import { datetimeStringWithSlash } from '../../../libs/date';
import A from '../../atoms/A';
import AlertDialog from '../../atoms/AlertDialog';
import MessageForm, {
  FileData,
  FormData,
} from 'components/molecules/forms/MessageForm';
import Button from 'components/atoms/Button';
import { useMediaQuery } from 'react-responsive';
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement,
  ReflexHandle,
} from 'react-reflex';
import PageTitle from 'components/atoms/PageTitle';
import SearchMessagesForm from 'components/molecules/forms/SearchMessagesForm';
import Tab, { Item } from 'components/molecules/Tab';
import { useBlocker, useNavigate } from 'react-router-dom';
import {
  DISPLAY_FILENAME_EXTENSIONS,
  DOWNLOAD_FILE_EXTENSIONS,
} from 'libs/constants';
import { downloadFileFromURL } from 'libs/downloadFileFromUrl';
import { getThumbnail } from 'libs/getThumbnail';
import { UserModel } from 'api/model/user';
import ReactionList from 'components/molecules/ReactionList';
import MessageMenuButton from 'components/organisms/MessageMenuButton';
import { linkifyAndSanitizeHTML } from 'libs/html';
import { QuotableType } from 'api/message';
import { QuoteBox } from 'components/organisms/QuoteBox';

const defaultProps = {
  messages: [],
  messageCount: 0,
  getMessagesNum: 1,
};

export type Props = {
  children?: ReactNode;
  messages: MessageModel[];
  messageCount: number;
  getMessagesNum: number;
  searchedQuery: string;
  fetching: boolean;
  user: UserModel;
  onDelete: (fileId: number, messageId: number) => void;
  onDeleteMessage: (messageId: number, currentTab: string) => void;
  onSubmit: (
    body: string,
    files: FileData[],
    status: string,
    scheduledAt: Date | null,
    currentTab: string,
    quotableId?: number,
    quotableType?: QuotableType
  ) => void;
  onUpdate: (
    messageId: number,
    body: string,
    files: FileData[],
    status: string,
    scheduledAt: Date | null,
    currentTab: string
  ) => void;
  fetchNextPage: (filterType: string, searchkeywords: string) => void;
  fetchPreviousPage: (filterType: string, searchKeywords: string) => void;
  handedOver4monthsAgo: boolean;
  onChangeSearchMessages: (
    filterType: string,
    searchkeywords: string,
    serialNumber?: number
  ) => void;
  handleCreatePinnedMessage: (messageId: number) => void;
  handleDeletePinnedMessage: (
    messageId: number,
    pinnedMessageId: number
  ) => void;
  handleCreateMessageReaction: (messageId: number, emojiCode: string) => void;
  handleDeleteMessageReaction: (messageId: number, reactionId: number) => void;
  focusedMessageNum?: number;
  resetFocusedMessageNum: () => void;
} & typeof defaultProps;

export type TabType = 'posted' | 'pinned' | 'draft' | 'scheduled';

export default function Messages(props: Props) {
  const {
    messages,
    messageCount,
    user,
    searchedQuery,
    onDelete,
    onDeleteMessage,
    onSubmit,
    onUpdate,
    fetchNextPage,
    fetchPreviousPage,
    getMessagesNum,
    fetching,
    handedOver4monthsAgo,
    onChangeSearchMessages,
    handleCreatePinnedMessage,
    handleDeletePinnedMessage,
    handleCreateMessageReaction,
    handleDeleteMessageReaction,
    focusedMessageNum,
    resetFocusedMessageNum,
  } = props;

  const [openFileBaloonId, setOpenFileBaloonId] = useState(0);
  const [openMessageBaloonId, setOpenMessageBaloonId] = useState(0);
  const [deleteFileId, setDeleteFileId] = useState(0);
  const [deleteMessageId, setDeleteMessageId] = useState(0);
  const [openHandedOveredAlert, setOpenHandedOveredAlert] = useState(false);
  const [openFileAlert, setOpenFileAlert] = useState(false);
  const [openMessageAlert, setOpenMessageAlert] = useState(false);
  const [showInputForm, setShowInputForm] = useState(false);
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [status, setStatus] = useState('published');
  const [formState, setFormState] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabType>('posted');
  const [selectedMessage, setSelectedMessage] = useState<MessageModel | null>(
    null
  );
  const [showSearch, setShowSearch] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState('');
  const sanitizer = DOMPurify.sanitize;
  const isSP = useMediaQuery({ maxWidth: 768 });
  const [inputValue, setInputValue] = useState('');
  const [openIncompleteAlert, setOpenIncompleteAlert] = useState(false);
  const [openTransitionAlert, setOpenTransitionAlert] = useState(false);
  const [openTooManyCharactersAlert, setOpenTooManyCharactersAlert] =
    useState(false);
  const [confirmTransition, setConfirmTransition] = useState(false);
  const [nextLocationPath, setNextLocationPath] = useState('');
  const [pageHeight, setPageHeight] = useState('100vh');
  const [isPWA, setIsPWA] = useState(false);
  const [isRotating, setIsRotating] = useState(false); // SP時回転中かどうかの状態
  const [prevShowInputForm, setPrevShowInputForm] = useState(false); // 直前のshowInputFormの状態を保持
  const [previousScrollHeight, setPreviousScrollHeight] = useState<number>(); // 上スクロールの追加読み込み時にスクロールの高さを保持する
  const [scheduledTime, setScheduledTime] = useState<Date | null>(null);
  const [quoteMessage, setQuoteMessage] = useState<MessageModel | null>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const focusedItemRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  interface VisualViewport {
    height: number;
    width: number;
    scale: number;
    offsetLeft: number;
    offsetTop: number;
    pageLeft: number;
    pageTop: number;
    addEventListener: (
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | AddEventListenerOptions
    ) => void;
    removeEventListener: (
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | EventListenerOptions
    ) => void;
  }

  // NOTE: ここからSP時の掲示板入力フォームがキーボードの裏に隠れてしまうことを防止する処理
  const updateHeight = () => {
    if (pageRef.current) {
      const viewport = pageRef.current.getBoundingClientRect();
      setPageHeight(`${viewport.height}px`);
    }
  };

  const handleResize = (viewport: VisualViewport | null, isPWA: boolean) => {
    if (viewport) {
      const newHeight = `${viewport.height}px`;
      setPageHeight(newHeight);
      if (pageRef.current) {
        pageRef.current.style.maxHeight = newHeight;
        if (isPWA) {
          window.scrollTo(0, 0);
        } else {
          pageRef.current.style.bottom = `${
            window.innerHeight - viewport.height
          }px`;
        }
      }
    } else {
      // visualViewportがサポートされていない場合はフォールバック
      setPageHeight('100vh');
      if (pageRef.current) {
        pageRef.current.style.maxHeight = '100vh';
        if (!isPWA) {
          pageRef.current.style.bottom = '0px';
        }
      }
    }
  };

  const unblock = useBlocker(({ currentLocation, nextLocation }): any => {
    if (
      inputValue !== '' &&
      !confirmTransition &&
      currentLocation.pathname !== nextLocation.pathname
    ) {
      setOpenTransitionAlert(true);
      setNextLocationPath(nextLocation.pathname);
      return false;
    }
    return undefined;
  });
  const navigate = useNavigate();

  const handleOrientationChange = (handleResize: () => void) => {
    setIsRotating(true); // 回転中のフラグを立てる
    setShowInputForm(false); // 回転中は入力フォームを非表示にする

    // 回転が終わった後の処理を少し遅らせる
    setTimeout(() => {
      handleResize(); // 回転後のリサイズ処理を呼び出す
      setIsRotating(false); // 回転中のフラグを下ろす
    }, 500); // 適切な遅延時間を設定
  };

  useEffect(() => {
    if (isSP) {
      const viewport = (window as any).visualViewport;
      setIsPWA(
        (window.navigator as any).standalone ||
          window.matchMedia('(display-mode: standalone)').matches
      );
      const boundHandleResize = () => handleResize(viewport, isPWA);
      const boundHandleOrientationChange = () =>
        handleOrientationChange(boundHandleResize);

      // 初期サイズの設定
      updateHeight();
      boundHandleResize();
      // リサイズイベントリスナーの追加
      if (viewport) {
        viewport.addEventListener('resize', boundHandleResize);
        window.addEventListener(
          'orientationchange',
          boundHandleOrientationChange
        );
      }
      // クリーンアップ
      return () => {
        if (viewport) {
          viewport.removeEventListener('resize', boundHandleResize);
          window.removeEventListener(
            'orientationchange',
            boundHandleOrientationChange
          );
        }
      };
    }
  }, [isSP, isPWA]);

  useEffect(() => {
    if (!isRotating) {
      setShowInputForm(prevShowInputForm); // 直前の状態に復元
    }
  }, [isRotating]);
  // NOTE: ここまでSP時対応

  const Status = {
    PUBLISHED: 'published',
    DRAFT: 'draft',
    SCHEDULED: 'scheduled',
  };

  useEffect(() => {
    document.body.classList.add('MessageBody');
    return () => {
      document.body.classList.remove('MessageBody');
    };
  }, []);

  useEffect(() => {
    const removeListener = () => {
      if (openTransitionAlert) {
        setOpenTransitionAlert(false);
        if (unblock.state === 'blocked') {
          navigate(nextLocationPath, { replace: true });
        } else {
          navigate(nextLocationPath);
        }
      }
    };
    return removeListener();
  }, [navigate, nextLocationPath, openTransitionAlert, unblock]);

  useEffect(() => {
    if (confirmTransition) {
      navigate(nextLocationPath);
    }
  }, [confirmTransition, navigate, nextLocationPath]);

  useEffect(() => {
    if (focusedMessageNum && !fetching) {
      focusedItemRef?.current?.scrollIntoView();
    }
  }, [focusedMessageNum, fetching]);

  useEffect(() => {
    // メッセージデータを追加読み込みしてページ上部に追加した後、前のスクロール位置に戻す
    if (previousScrollHeight && scrollContainerRef.current) {
      const newScrollHeight = scrollContainerRef.current.scrollHeight;
      const heightDifference = newScrollHeight - previousScrollHeight;
      scrollContainerRef.current.scrollTop = heightDifference;
      setPreviousScrollHeight(undefined);
    }
  }, [messages]);

  const handleScroll = (e: any) => {
    const element = e.currentTarget;
    const { scrollHeight, scrollTop, clientHeight } = element;

    /* Safariでスクロール時、イベントが重複して発火してしまうのを防ぐ */
    const noScroll = (e: any) => {
      e.preventDefault();
    };

    const no_scroll = function () {
      document.addEventListener('mousewheel', noScroll, { passive: false });
      document.addEventListener('touchmove', noScroll, { passive: false });
    };

    const return_scroll = function () {
      document.removeEventListener('mousewheel', noScroll, {
        passive: false,
      } as EventListenerOptions);
      document.removeEventListener('touchmove', noScroll, {
        passive: false,
      } as EventListenerOptions);
    };
    // 画面を150%にするとスクロールできなくなるので-1している
    if (scrollHeight - 1 <= scrollTop + clientHeight && getMessagesNum > 0) {
      no_scroll();
      fetchNextPage(currentTab, searchKeywords);
      setTimeout(return_scroll, 100);
    }

    if (scrollTop === 0 && getMessagesNum > 0) {
      no_scroll();
      // メッセージの前ページを上部に追加する前のスクロール位置を保持する
      setPreviousScrollHeight(scrollContainerRef.current?.scrollHeight);

      fetchPreviousPage(currentTab, searchKeywords);
      setTimeout(return_scroll, 100);
    }
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    const buildParams = {
      html: newValue,
    };
    localStorage.setItem(
      `${process.env.REACT_APP_ENV_NAME}_NAVI_MESSAGE_PARAMS`,
      JSON.stringify(buildParams)
    );
  };

  const getParamsFromStorage = () => {
    const storageData = localStorage.getItem(
      `${process.env.REACT_APP_ENV_NAME}_NAVI_MESSAGE_PARAMS`
    );
    if (storageData) {
      const paramsFromStorage = JSON.parse(storageData);
      setInputValue(paramsFromStorage.html);
    }
  };

  const resetParamsInStorage = () => {
    const storageData = localStorage.getItem(
      `${process.env.REACT_APP_ENV_NAME}_NAVI_MESSAGE_PARAMS`
    );
    if (storageData) {
      localStorage.removeItem(
        `${process.env.REACT_APP_ENV_NAME}_NAVI_MESSAGE_PARAMS`
      );
    }
  };

  const handleCloseForm = () => {
    if (formState) {
      setOpenIncompleteAlert(true);
    } else {
      setShowInputForm(false);
      setPrevShowInputForm(false);
      setOpenIncompleteAlert(false);
      setSelectedMessage(null);
      setQuoteMessage(null);
    }
    return true;
  };

  const handleMessageImageMenu = (
    file: DataFileModel,
    message: MessageModel
  ) => {
    if (handedOver4monthsAgo) {
      setOpenHandedOveredAlert(true);
    } else {
      if (openFileBaloonId === file.id) {
        setOpenFileBaloonId(0);
      } else {
        setOpenFileBaloonId(file.id);
        setDeleteFileId(file.id);
        setOpenMessageBaloonId(0);
      }
      if (openMessageBaloonId === message.message.id) {
        setOpenMessageBaloonId(0);
      } else {
        setDeleteMessageId(message.message.id);
        setOpenMessageBaloonId(0);
      }
    }
  };

  const handleDeleteMessageMenu = (message: MessageModel) => {
    if (handedOver4monthsAgo) {
      setOpenHandedOveredAlert(true);
    } else {
      setDeleteMessageId(message.message.id);
      setOpenFileBaloonId(0);
      setOpenMessageAlert(true);
    }
  };

  const handleDelete = (messageId: number, currentTab: string) => {
    onDeleteMessage(messageId, currentTab);
    setShowInputForm(false);
    setFormState(false);
    setInputValue('');
    setSelectedMessage(null);
    resetParamsInStorage();
  };

  const handleCloseHandedOveredAlert = () => {
    setOpenHandedOveredAlert(false);
  };

  const handleConfirmTransitionAlert = () => {
    setOpenTransitionAlert(false);
    setShowInputForm(false);
    setPrevShowInputForm(false);
    setFormState(false);
    setInputValue('');
    setSelectedMessage(null);
    setQuoteMessage(null);
    resetParamsInStorage();
    setConfirmTransition(true);
  };

  const handleAgreeIncompleteAlert = () => {
    setOpenIncompleteAlert(false);
    setShowInputForm(false);
    setPrevShowInputForm(false);
    setFormState(false);
    setInputValue('');
    setSelectedMessage(null);
    setQuoteMessage(null);
    resetParamsInStorage();
  };

  const handleSubmit = async (formData: FormData) => {
    if (formData.messageId) {
      onUpdate(
        formData.messageId,
        formData.body,
        formData.files,
        status,
        formData.scheduledAt,
        getTabTypeAfterSubmit()
      );
    } else {
      onSubmit(
        formData.body,
        formData.files,
        status,
        formData.scheduledAt,
        getTabTypeAfterSubmit(),
        quoteMessage?.message.id,
        quoteMessage ? 'Message' : undefined
      );
    }
    setCurrentTab(getTabTypeAfterSubmit());
    setTriggerSubmit(false);
    setShowInputForm(false);
    setPrevShowInputForm(false);
    setFormState(false);
    setInputValue('');
    resetParamsInStorage();
    setStatus('published');
    setSelectedMessage(null);
    setQuoteMessage(null);
    document.getElementById('pageTop')?.scrollTo(0, 0);
  };

  const getTabTypeAfterSubmit = (): TabType => {
    switch (status) {
      case 'draft':
        return 'draft';
      case 'published':
        return 'posted';
      case 'scheduled':
        return 'scheduled';
      default:
        return 'posted';
    }
  };

  const handleClickAdd = () => {
    if (handedOver4monthsAgo) {
      setOpenHandedOveredAlert(true);
      return;
    }
    getParamsFromStorage();
    setShowInputForm(true);
    setPrevShowInputForm(true);
  };

  // 下書きと予約送信はメッセージクリックでフォーム開く
  const handleMessageClick = (message: MessageModel) => {
    resetFocusedMessageNum();
    if (
      currentTab === 'draft' ||
      (currentTab === 'scheduled' && !showInputForm)
    ) {
      setFormState(true);
      setSelectedMessage(message);
      setInputValue(message.message.body);
      setScheduledTime(message.message.scheduled_at);
      setShowInputForm(true);
    }
  };

  const onClick = (item: Item) => {
    onChangeSearchMessages(item.path, searchKeywords);
    setCurrentTab(item.path);
  };

  const tab = {
    items: [
      {
        id: 'posted',
        name: '投稿済',
        path: 'posted',
      },
      {
        id: 'pinned',
        name: 'ピン止め',
        path: 'pinned',
      },
      {
        id: 'draft',
        name: '下書き',
        path: 'draft',
      },
      {
        id: 'scheduled',
        name: '送信予約',
        path: 'scheduled',
      },
    ],
    tabType: 'Tab',
    current: currentTab,
    onClick,
  };

  const switchModifier = (total: number): string => {
    if (total < 100) {
      return '';
    } else if (total < 1000) {
      return '-threeDigits';
    } else {
      return '-fourDigits';
    }
  };

  const handleSetSearchKeywords = (keywords: string) => {
    setSearchKeywords(keywords);
  };

  const togglePin = (
    pinned: boolean,
    messageId: number,
    pinnedMessageId: number
  ) => {
    if (pinned) {
      return handleCreatePinnedMessage(messageId);
    } else {
      return handleDeletePinnedMessage(messageId, pinnedMessageId);
    }
  };

  const handleFileClick = (
    url: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    filename: string
  ) => {
    if (event.target) {
      event.preventDefault();
    }
    if (url.includes('.glb')) {
      event.preventDefault(); // デフォルトのリンククリック動作を停止
      localStorage.setItem('three_d_file_url', url);
      const viewerURL = '/three_d_viewer';
      window.open(viewerURL, '_blank', 'noreferrer'); // 別タブで3DファイルのURLを開く
    } else if (
      DOWNLOAD_FILE_EXTENSIONS.some((extension) => filename.includes(extension))
    ) {
      downloadFileFromURL(url, filename);
    } else {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  const shouldDisplayDeleteButton = (message: MessageModel) => {
    return message.sender.is_user && !message.message.deleted;
  };

  const shouldDisplayShowInAllItemsButton = () => {
    return currentTab === 'pinned' || searchedQuery !== '';
  };

  const shouldDisplayQuoteButton = (message: MessageModel) => {
    return (
      currentTab !== 'draft' &&
      currentTab !== 'scheduled' &&
      !message.message.deleted
    );
  };

  const shouldDisplayMenuButton = (message: MessageModel) => {
    return (
      shouldDisplayDeleteButton(message) ||
      shouldDisplayShowInAllItemsButton() ||
      shouldDisplayQuoteButton(message)
    );
  };

  const shouldDisplayQuote = (message: MessageModel) => {
    return (
      message.message.task ||
      message.message.article ||
      message.message.quote_message
    );
  };

  const showMessageInAllItems = (serialNumber: number) => {
    setCurrentTab('posted');
    setSearchKeywords('');
    onChangeSearchMessages('posted', '', serialNumber);
    setOpenMessageAlert(false);
  };

  const showReactionList = (): boolean => {
    switch (currentTab) {
      case 'posted':
      case 'pinned':
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <PageTitle>掲示板</PageTitle>
      <div
        className={`Message ${isSP && showInputForm ? '-hide-footer' : ''}`}
        ref={pageRef}
        style={
          isSP && !isPWA //PWA時にposition:fixedを指定すると崩れる
            ? {
                maxHeight: pageHeight,
                bottom: '0px',
                position: 'fixed',
                boxSizing: 'border-box',
                width: '100%',
              }
            : { boxSizing: 'border-box' }
        }
      >
        <AlertDialog
          title={'編集不可'}
          description={
            'お引渡しから4ヶ月が経過しているため、編集はできません。'
          }
          open={openHandedOveredAlert}
          onAgree={() => handleCloseHandedOveredAlert()}
          onClose={() => handleCloseHandedOveredAlert()}
          singleButton
        />
        <AlertDialog
          title={
            currentTab === 'draft'
              ? '下書き編集を中止しますか？'
              : '入力を中止しますか？'
          }
          description={
            currentTab === 'draft'
              ? '下書き保存時の内容は保持されますが、追加で入力した内容は破棄されます。'
              : '入力途中のメッセージがあります。中止すると内容は破棄されます。'
          }
          open={openIncompleteAlert}
          onAgree={() => handleAgreeIncompleteAlert()}
          onClose={() => setOpenIncompleteAlert(false)}
        />
        <AlertDialog
          title={'入力を中止してページを離れますか？'}
          description={
            '入力途中のメッセージがあります。このページを離れると内容は破棄されます。'
          }
          open={openTransitionAlert}
          onAgree={() => handleConfirmTransitionAlert()}
          onClose={() => setOpenTransitionAlert(false)}
        />
        <AlertDialog
          title={'入力された文字数が多すぎます'}
          description={'入力された文字数が2,000文字を超えています。'}
          open={openTooManyCharactersAlert}
          onAgree={() => setOpenTooManyCharactersAlert(false)}
          onClose={() => setOpenTooManyCharactersAlert(false)}
          singleButton
        />
        <ReflexContainer
          orientation="horizontal"
          className="Message__reflex-container"
        >
          <ReflexElement className="Message__reflex-element -list">
            <div
              id="pageTop"
              className="Message__container"
              ref={scrollContainerRef}
              onScroll={handleScroll}
            >
              <div className="Announce__note message">
                <p>
                  誠に勝手ながら、各担当者への掲示板の投稿通知は水曜日を除く8〜19時と
                  <br className="u-display-pc" />
                  させて頂いております。通知時間外のご投稿の確認は数日かかる場合もござ
                  <br className="u-display-pc" />
                  いますので、ご理解のほど、お願い申し上げます。
                </p>
              </div>
              <div className="MessageList">
                <div
                  className={`MessageList__header ${
                    showSearch ? '-sp-show-search' : ' -sp-hide-search'
                  }`}
                >
                  <div className="MessageList__header-info">
                    <div className="MessageList__flex">
                      <h2 className="MessageList__title">メッセージリスト</h2>
                      <p
                        className={`MessageList__item-number ${switchModifier(
                          messageCount
                        )}`}
                      >
                        {messageCount < 1000 ? messageCount : '999+'}
                      </p>
                      <div
                        className="MessageList__sp-search-btn"
                        onClick={() => setShowSearch(!showSearch)}
                      >
                        <img
                          src={`/assets/img/common/icon/${
                            showSearch ? 'loupe_red.svg' : 'loupe_black.svg'
                          }`}
                          alt="検索"
                        />
                      </div>
                    </div>
                  </div>
                  <Tab {...tab} />
                  <SearchMessagesForm
                    handleSearchedMessageData={(searchByEnter, keywords) => {
                      // 通常検索
                      if (searchByEnter) {
                        onChangeSearchMessages(currentTab, searchKeywords);
                        // 履歴から検索
                      } else {
                        onChangeSearchMessages(currentTab, keywords);
                      }
                    }}
                    handleSetSearchKeywords={(keywords) =>
                      handleSetSearchKeywords(keywords)
                    }
                    queryValue={searchKeywords}
                    setQueryValue={setSearchKeywords}
                  />
                </div>
                <div>
                  {/* TODO: ファイル削除時 */}
                  {messages.length !== 0 &&
                    messages.map((message) => {
                      if (message.message.message_type === 'normal') {
                        return (
                          <div
                            key={message.message.id}
                            ref={
                              message.message.serial_number ===
                              focusedMessageNum
                                ? focusedItemRef
                                : undefined
                            }
                            className={
                              currentTab === 'draft' ||
                              currentTab === 'scheduled'
                                ? 'is-editable'
                                : ''
                            }
                            onClick={() => handleMessageClick(message)}
                          >
                            {message.message.mail_reserved && (
                              <div
                                className={`Message__reservation ${
                                  currentTab !== 'pinned' &&
                                  message.message.pinned_message_id
                                    ? '-pinned'
                                    : ''
                                } ${
                                  message.message.serial_number ===
                                  focusedMessageNum
                                    ? '-focused'
                                    : ''
                                }`}
                              >
                                <p className="Message__reservation-mark">
                                  通知予約済
                                </p>
                              </div>
                            )}
                            <div
                              className={`Message__item ${
                                message.sender.is_user ? '-customer' : '-mh'
                              } ${
                                currentTab !== 'pinned' &&
                                message.message.pinned_message_id
                                  ? '-pinned'
                                  : ''
                              } ${
                                message.message.serial_number ===
                                focusedMessageNum
                                  ? '-focused'
                                  : ''
                              }`}
                            >
                              <div className="Message__item-left">
                                <img
                                  className="Message__item-icon"
                                  src={`/assets/img/messages/icon/${
                                    message.sender.is_user
                                      ? 'customer'
                                      : 'mitsuihome'
                                  }.svg`}
                                  alt=""
                                />
                                {message.message.serial_number !== 0 && (
                                  <p className="Message__item-number">
                                    {message.message.serial_number}
                                  </p>
                                )}
                              </div>
                              <div className="Message__item-main">
                                <p className="Message__item-date">
                                  {message.message.status === 'scheduled' &&
                                  message.message.scheduled_at
                                    ? '送信予定日時： ' +
                                      datetimeStringWithSlash(
                                        message.message.scheduled_at
                                      )
                                    : datetimeStringWithSlash(
                                        message.message.sent_at
                                      )}
                                </p>
                                <div className="u-flex-02">
                                  <p className="Message__item-person">
                                    {message.sender.name
                                      ? message.sender.name
                                      : ''}
                                  </p>
                                  {shouldDisplayMenuButton(message) && (
                                    <MessageMenuButton
                                      onClickDeleteButton={() =>
                                        handleDeleteMessageMenu(message)
                                      }
                                      onClickShowInAllItemsButton={() => {
                                        showMessageInAllItems(
                                          message.message.serial_number
                                        );
                                      }}
                                      onClickQuoteButton={() => {
                                        setQuoteMessage(message);
                                        handleClickAdd();
                                      }}
                                      displayDeleteButton={shouldDisplayDeleteButton(
                                        message
                                      )}
                                      displayShowInAllItemsButton={shouldDisplayShowInAllItemsButton()}
                                      displayQuoteButton={shouldDisplayQuoteButton(
                                        message
                                      )}
                                    />
                                  )}
                                  {((message.message.deleted &&
                                    currentTab === 'pinned') ||
                                    (!message.message.deleted &&
                                      currentTab !== 'draft' &&
                                      currentTab !== 'scheduled')) && (
                                    <div
                                      onClick={() => {
                                        if (!fetching) {
                                          togglePin(
                                            message.message.pinned_message_id
                                              ? false
                                              : true,
                                            message.message.id,
                                            message.message.pinned_message_id ||
                                              0
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        className={`Pin__icon -pin ${
                                          message.message.pinned_message_id
                                            ? '-on'
                                            : ''
                                        }`}
                                        src={`/assets/img/common/icon/pin_${
                                          message.message.pinned_message_id
                                            ? 'on'
                                            : 'off'
                                        }.svg`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="Message__item-message">
                                  {message.message.deleted ? (
                                    <div
                                      className="Message__item-image-deleted"
                                      key={message.message.id}
                                    >
                                      <div className="Message__item-image-deleted-label">
                                        <span>投稿が削除されました</span>
                                      </div>
                                      <span className="Message__item-image-deleted-date">
                                        {datetimeStringWithSlash(
                                          message.message.deleted_at
                                        ) + message.message.deleter_name}
                                      </span>
                                    </div>
                                  ) : (
                                    <>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: linkifyAndSanitizeHTML(
                                            message.message.body
                                          ),
                                        }}
                                      />

                                      {shouldDisplayQuote(message) && (
                                        <QuoteBox
                                          task={message.message.task}
                                          article={message.message.article}
                                          message={
                                            message.message.quote_message
                                          }
                                          onClickMessageQuote={
                                            showMessageInAllItems
                                          }
                                        />
                                      )}
                                      {message.message.data_files.map(
                                        (file) => {
                                          if (file.deleted) {
                                            if (!message.message.deleted) {
                                              return (
                                                <div
                                                  className="Message__item-image-deleted"
                                                  key={file.id}
                                                >
                                                  <div className="Message__item-image-deleted-label">
                                                    <span>
                                                      ファイルが削除されました
                                                    </span>
                                                  </div>
                                                  <p>
                                                    {datetimeStringWithSlash(
                                                      file.deleted_at
                                                    ) + file.deleter_name}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          } else {
                                            return (
                                              <div
                                                key={file.id}
                                                className="Message__item-image"
                                              >
                                                <A
                                                  href={file.url}
                                                  onClick={(
                                                    event: React.MouseEvent<
                                                      HTMLAnchorElement,
                                                      MouseEvent
                                                    >
                                                  ) =>
                                                    handleFileClick(
                                                      file.url,
                                                      event,
                                                      file.name
                                                    )
                                                  }
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <img
                                                    src={getThumbnail(
                                                      file.url,
                                                      file.thumb_url
                                                    )}
                                                    alt={file.name}
                                                  />
                                                  {/* サムネイル画像がデフォルトのものを使用しているファイルについては識別できるようファイル名を表示 */}
                                                  {DISPLAY_FILENAME_EXTENSIONS.some(
                                                    (extension) =>
                                                      file.url.includes(
                                                        extension
                                                      )
                                                  ) && (
                                                    <p className="Message__item-image-name">
                                                      {file.name}
                                                    </p>
                                                  )}
                                                </A>
                                                {message.message.status !==
                                                  'draft' &&
                                                  message.sender.is_user && (
                                                    <>
                                                      <span
                                                        className="Message__item-image-menu -file"
                                                        onClick={() =>
                                                          handleMessageImageMenu(
                                                            file,
                                                            message
                                                          )
                                                        }
                                                      />
                                                      <span
                                                        className={`Message__item-image-balloon -file ${
                                                          openFileBaloonId ===
                                                            file.id && '-show'
                                                        }`}
                                                      >
                                                        <span
                                                          className="-delete"
                                                          onClick={() =>
                                                            setOpenFileAlert(
                                                              true
                                                            )
                                                          }
                                                        >
                                                          削除
                                                        </span>
                                                      </span>
                                                    </>
                                                  )}
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                      {showReactionList() && (
                                        <ReactionList
                                          reactions={message.message.reactions}
                                          user={user}
                                          onAdded={(emojiCode) =>
                                            handleCreateMessageReaction(
                                              message.message.id,
                                              emojiCode
                                            )
                                          }
                                          onDeleted={(reactionId) =>
                                            handleDeleteMessageReaction(
                                              message.message.id,
                                              reactionId
                                            )
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="Message__item -info"
                            key={message.message.id}
                          >
                            <div className="Message__item-wrap">
                              <p className="Message__item-title">
                                {message.message.title}
                              </p>
                              {/* FIXME: aタグが含まれる可能性があるのでdangerouslySetInnerHTMLを設定している */}
                              <p
                                className="Message__item-message"
                                dangerouslySetInnerHTML={{
                                  __html: sanitizer(message.message.body),
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                  <AlertDialog
                    title="添付ファイルの削除"
                    description="添付ファイルを削除しますか？"
                    open={openFileAlert}
                    onClose={() => {
                      setOpenFileAlert(false);
                      setOpenFileBaloonId(0);
                    }}
                    onAgree={() => onDelete(deleteFileId, deleteMessageId)}
                  />
                  <AlertDialog
                    title="メッセージの削除"
                    description="メッセージを削除しますか？"
                    open={openMessageAlert}
                    onClose={() => {
                      setOpenMessageAlert(false);
                      setOpenMessageBaloonId(0);
                    }}
                    onAgree={() => handleDelete(deleteMessageId, currentTab)}
                  />
                </div>
                {!showInputForm && currentTab != 'draft' && (
                  <span
                    className="Message__add-btn u-print-hidden"
                    onClick={handleClickAdd}
                  />
                )}
              </div>
            </div>
          </ReflexElement>
          {showInputForm && (
            <ReflexSplitter className="Message__reflex-splitter" />
          )}
          {showInputForm && (
            <ReflexElement
              size={Math.max(window.innerHeight / (isSP ? 6 : 3), 107)}
              className="Message__reflex-element -form"
            >
              <ReflexHandle className="Message__reflex-handle">
                <div className="bar" />
                <div className="bar" />
              </ReflexHandle>
              <div className="Message__input-form">
                <MessageForm
                  onSubmit={handleSubmit}
                  checkFormState={setFormState}
                  characterCountState={setOpenTooManyCharactersAlert}
                  triggerSubmit={triggerSubmit}
                  onInputChange={handleInputChange}
                  selectedMessage={selectedMessage}
                  quoteMessage={quoteMessage}
                  inputValue={inputValue}
                  isSP={isSP}
                  onStatusChange={setStatus}
                  scheduledTime={scheduledTime}
                  onScheduledTimeChange={setScheduledTime}
                />
                <div className="Message__input-form-buttons">
                  <div
                    className="Message__input-cancel"
                    onClick={() => handleCloseForm()}
                  >
                    <img src="/assets/img/common/icon/close.svg" alt="" />
                  </div>
                  <div className="draft-btn">
                    <Button
                      variant="secondary"
                      type="submit"
                      onClick={() => {
                        setTriggerSubmit(true);
                        setStatus(Status.DRAFT);
                      }}
                      disabled={!formState || status === 'scheduled'}
                    >
                      下書き
                    </Button>
                  </div>
                  <div className="send-btn">
                    <Button
                      variant="default"
                      type="submit"
                      onClick={() => setTriggerSubmit(true)}
                      disabled={!formState}
                    >
                      {status === 'scheduled' ? '予約' : '送信'}
                    </Button>
                  </div>
                </div>
              </div>
            </ReflexElement>
          )}
        </ReflexContainer>
      </div>
    </>
  );
}

Messages.defaultProps = defaultProps;
