import React, { ChangeEvent, useState } from 'react';
import ModalBase from '../../atoms/ModalBase';

import CloseIcon from '@material-ui/icons/Close';
import { TextareaAutosize } from '@material-ui/core';
import { QuotableType } from 'api/message';

const defaultProps = {};

export type ModalProps = {
  open: boolean;
  onClose: (reason: string) => void;
  modalTitle: string;
  id?: number;
  handlePostMessage: (
    body: string,
    quotableId: number,
    quotableType: QuotableType
  ) => Promise<void>;
  inputHint: string;
};

export default function PostMessageModal(props: ModalProps) {
  const { open, onClose, modalTitle, id, handlePostMessage, inputHint } = props;
  const [body, setBody] = useState('');
  const onChangeBody = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => setBody(e.target.value as string);

  const handleClose = (reason: string) => {
    onClose(reason);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handlePostMessage(body, Number(id), 'Task');
    setBody('');
  };

  return (
    <ModalBase
      onClose={() => handleClose('backdropClick')}
      open={open}
      className="Modal"
    >
      <div className="Modal -post-message-modal">
        <div className="post-message-modal-header">
          <button
            className="close-button"
            onClick={() => handleClose('backdropClick')}
          >
            <CloseIcon />
          </button>
          <p className="title">{modalTitle}</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="post-message-modal-main">
            <TextareaAutosize
              maxRows={10}
              minRows={10}
              aria-label="maximum height"
              placeholder={inputHint}
              defaultValue=""
              onChange={onChangeBody}
              style={{ width: '80%', resize: 'none' }}
            />
          </div>
          <div className="post-message-modal-remarks">
            <p>※ 質問内容は自動で掲示板に投稿されます。</p>
            <p>
              ※ 掲示板または打ち合わせの際に担当者より回答させていただきます。
            </p>
            <p>
              ※
              各担当者への掲示板の投稿通知は水曜日を除く8〜19時となっております。
            </p>
          </div>
          <div className="post-message-modal-footer">
            <button
              type="submit"
              className={`post-button ${
                body ? 'post-button-has-change' : 'post-button-no-change'
              }`}
              disabled={body ? false : true}
            >
              送信
            </button>
          </div>
        </form>
      </div>
    </ModalBase>
  );
}

PostMessageModal.defaultProps = defaultProps;
