import React, { useState, useEffect, ReactNode } from 'react';
import { AppointmentModel } from '../../../api/model/appointment';
import { AgendaModel } from '../../../api/model/agenda';
import { shortdateStringJp, datetimeStringJp, isNullValue } from 'libs/date';
import PageTitle from '../../atoms/PageTitle';
import A from '../../atoms/A';
import Calendar from '../../atoms/Calender';
import axios from 'axios';
import _ from 'lodash';
import MediaQuery from 'react-responsive';
import * as Sentry from '@sentry/browser';
import { HouseholdModel } from '../../../api/model/household';
import { displayHouseholds } from 'features/appointment/Schedules';
import { isBefore, startOfDay } from 'date-fns';

const defaultProps = {
  appointments: [],
};

type Props = {
  appointments: AppointmentModel[];
  children?: ReactNode;
  slideOpen: boolean;
  hasUsingHouseholds: boolean;
} & typeof defaultProps;

export default function Schedule(props: Props) {
  const { appointments, slideOpen, hasUsingHouseholds } = props;
  const [holydays, setHolydays] = useState<Date[]>([]);

  // redux にいれる必要がないので直接コール
  useEffect(() => {
    axios
      .get('https://holidays-jp.github.io/api/v1/date.json')
      .then(function (response) {
        const { data } = response;
        setHolydays(
          _.map(data, (value, key) => {
            return new Date(key);
          })
        );
      })
      .catch(function (error) {
        // Sentry.captureException(error);
      });
  }, []);

  // 打合せ一覧のマイルストーン表示切替に使用
  const sortedItems = () => {
    const dupAppointments: Array<AppointmentModel | any> = appointments.slice();
    const sorted: Array<AppointmentModel | any> = dupAppointments.sort(
      (a, b) => {
        const x = new Date(a.date);
        const y = new Date(b.date);
        return x > y ? 1 : x < y ? -1 : 0;
      }
    );
    return sorted.reverse();
  };

  // カレンダーのマーク表示切替に使用
  const groupedEvent = () => {
    const groupBy = (
      appointments_array: AppointmentModel | any,
      key: string
    ) => {
      return appointments_array.reduce((new_array: any, appoint: any) => {
        (new_array[appoint[key]] = new_array[appoint[key]] || []).push(
          appoint.appointment_type
        );
        return new_array;
      }, {});
    };

    const sortedAppointments: Array<AppointmentModel | any> =
      appointments.filter((appointment) => !isNullValue(appointment.date));
    return groupBy(sortedAppointments, 'date');
  };

  /*
  打ち合わせ: red
  打ち合わせ+設計工程: starday-red
  打ち合わせ+工事工程: red-blue
  設計工程マイルストーン: starday
  工事工程マイルストーン: blue
  お引き渡し: blue-square
  ご契約: red-square
  今日: today
  */
  const buildMarkingDates = () => {
    if (appointments.length === 0) return [];
    const events: any = groupedEvent();

    return Object.keys(events).map((key) => {
      const past = isBefore(new Date(key), startOfDay(new Date()))
        ? 'past'
        : '';
      if (
        events[key].includes('normal') &&
        events[key].includes('sekkei_process')
      ) {
        return {
          date: new Date(key),
          className: `starday-red ${past}`,
        };
      } else if (
        events[key].includes('normal') &&
        events[key].includes('kouji_process')
      ) {
        return { date: new Date(key), className: `red-blue ${past}` };
      } else if (events[key].includes('sekkei_process')) {
        return { date: new Date(key), className: 'starday' };
      } else if (events[key].includes('kouji_process')) {
        return { date: new Date(key), className: 'blue' };
      } else if (
        events[key].includes('handovered') &&
        events[key].includes('normal')
      ) {
        return {
          date: new Date(key),
          className: `blue-square red ${past}`,
        };
      } else if (events[key].includes('handovered')) {
        return {
          date: new Date(key),
          className: 'blue-square',
        };
      } else if (
        events[key].includes('contracted') &&
        events[key].includes('normal')
      ) {
        return {
          date: new Date(key),
          className: `red-square red ${past}`,
        };
      } else if (events[key].includes('contracted')) {
        return {
          date: new Date(key),
          className: 'red-square',
        };
      } else {
        return { date: new Date(key), className: `red ${past}` };
      }
    });
  };

  return (
    <>
      <PageTitle>スケジュール</PageTitle>
      <div id="pageTop" className="container -with-bar">
        <div id="Schedule">
          <MediaQuery query="(max-width: 768px)">
            <div
              className="Schedule__calendar"
              style={{
                left: slideOpen ? '33.0rem' : '0',
              }}
            >
              <div className="Schedule__calendar-inner">
                <Calendar
                  markingDates={buildMarkingDates()}
                  holydays={holydays}
                />
              </div>
              <div className="Schedule__calendar-controller" />
            </div>
          </MediaQuery>
          <MediaQuery query="(min-width: 769px)">
            <div className="Schedule__calendar">
              <div className="Schedule__calendar-inner">
                <Calendar
                  markingDates={buildMarkingDates()}
                  holydays={holydays}
                />
              </div>
              <div className="Schedule__calendar-controller" />
            </div>
          </MediaQuery>

          <div id="pageTop" className="Schedule__event">
            <div className="Schedule__event-inner">
              {appointments.length !== 0 &&
                sortedItems().map((appointment) => {
                  const past = isBefore(
                    new Date(appointment.date),
                    startOfDay(new Date())
                  )
                    ? '-past'
                    : '';
                  if (
                    appointment.appointment_type === 'sekkei_process' ||
                    appointment.appointment_type === 'kouji_process'
                  ) {
                    return (
                      <A
                        to={`/appointments/${appointment.id}`}
                        key={appointment.id}
                      >
                        <div
                          className="Schedule__milestone"
                          key={appointment.title}
                        >
                          <div
                            className={`Schedule__milestone-label ${
                              appointment.appointment_type === 'kouji_process'
                                ? '-icon-house'
                                : '-icon-star'
                            }`}
                          >
                            <span>{shortdateStringJp(appointment.date)}</span>
                            <span>{appointment.title}</span>
                          </div>
                        </div>
                      </A>
                    );
                  } else if (
                    appointment.appointment_type === 'contracted' ||
                    appointment.appointment_type === 'handovered'
                  ) {
                    return (
                      <A
                        to={`/appointments/${appointment.id}`}
                        key={appointment.id}
                      >
                        <div
                          className={`Schedule__event-item-${
                            appointment.appointment_type === 'contracted'
                              ? 'contract'
                              : 'extradition'
                          }`}
                        >
                          <div
                            className={`Schedule__event-label-${
                              appointment.appointment_type === 'contracted'
                                ? 'contract'
                                : 'extradition'
                            }`}
                          >
                            <div>
                              <p className="label-date">
                                {!isNullValue(appointment.date) &&
                                  shortdateStringJp(appointment.date)}
                              </p>
                              <p className="label-text">{appointment.title}</p>
                            </div>
                          </div>
                        </div>
                      </A>
                    );
                  } else {
                    return (
                      <div
                        className="Schedule__event-item"
                        key={appointment.id}
                      >
                        <A to={`/appointments/${appointment.id}`}>
                          <div className={'Schedule__event-label'}>
                            <span
                              className={`Schedule__event-label-date -red ${past}`}
                            >
                              {shortdateStringJp(appointment.date)}
                            </span>
                          </div>
                          <h2 className="Schedule__event-title">
                            {appointment.title}
                          </h2>
                          <div className="Schedule__event-desc">
                            <p className="text-time">
                              <span className="Schedule__event-desc-label">
                                時間：
                              </span>
                              <span>
                                {datetimeStringJp(appointment.begin_at)}〜
                                {datetimeStringJp(appointment.end_at)}
                              </span>
                            </p>
                            <p className="text-place">
                              <span className="Schedule__event-desc-label">
                                場所：
                              </span>
                              <span>{appointment.place}</span>
                            </p>
                            {displayHouseholds(
                              hasUsingHouseholds,
                              appointment
                            ) && (
                              <div className="Schedule__event-flex-box">
                                <span className="Schedule__event-desc-label">
                                  参加世帯：
                                </span>
                                <ul className="Schedule__event-household-list">
                                  {appointment.households.map(
                                    (
                                      household: HouseholdModel,
                                      index: number
                                    ) => (
                                      <li key={index}>{household.name}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </A>
                        {!past && appointment.display_conference && (
                          <div className="Schedule__event-desc -conference">
                            <A
                              to={appointment.conference_url}
                              className="Schedule__event-desc -link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="icon-link"
                                src="/assets/img/schedule/icon/join_conference.svg"
                                alt="オンライン打合せ"
                              />
                            </A>
                            <span className="Schedule__event-desc -url">
                              {appointment.conference_url.replace(
                                'https://',
                                ''
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Schedule.defaultProps = defaultProps;
