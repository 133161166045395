export const customEmojiData = [
  {
    id: 'custom',
    name: 'Custom',
    emojis: [
      {
        id: 'kakuninn_itashimasu',
        name: '確認いたします！',
        skins: [{ src: '/assets/img/custom_emoji/kakuninn_itashimasu.png' }],
      },
      {
        id: 'arigatou_gozaimasu',
        name: 'ありがとうございます！',
        skins: [{ src: '/assets/img/custom_emoji/arigatou_gozaimasu.png' }],
      },
      {
        id: 'kashikomari_mashita',
        name: 'かしこまりました！',
        skins: [{ src: '/assets/img/custom_emoji/kashikomari_mashita.png' }],
      },
      {
        id: 'kentou_itashimasu',
        name: '検討いたします！',
        skins: [{ src: '/assets/img/custom_emoji/kentou_itashimasu.png' }],
      },
      {
        id: 'oshirabe_itashimasu',
        name: 'お調べいたします！',
        skins: [{ src: '/assets/img/custom_emoji/oshirabe_itashimasu.png' }],
      },
      {
        id: 'shouchi_itashimashita',
        name: '承知いたしました！',
        skins: [{ src: '/assets/img/custom_emoji/shouchi_itashimashita.png' }],
      },
      {
        id: 'taiou_itashimasu',
        name: '対応いたします！',
        skins: [{ src: '/assets/img/custom_emoji/taiou_itashimasu.png' }],
      },
      {
        id: 'yoroshiku_onegaishimasu',
        name: 'よろしくお願いします！',
        skins: [
          { src: '/assets/img/custom_emoji/yoroshiku_onegaishimasu.png' },
        ],
      },
    ],
  },
];
