import axios from 'axios';

export const orderSelfstylingClient = axios.create({
  baseURL: `${process.env.REACT_APP_ORDER_SELFSTYLING_HOST}/api`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
export default orderSelfstylingClient;
